import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container } from '@mui/material';
import FAQJSON from './faq.json';
import IconTemplate from './components/IconTemplate';
import HelpIcon from '@mui/icons-material/Help';

const FaqGroupItem = ({ title, items }) => {
  return (
    <Container>
      <Box>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h6'}
          align={'left'}
        >
          {title}
        </Box>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box component={Accordion} key={i} paddingY={0} elevation={0}>
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id={`panel1a-header--${i}`}
              // padding={`${theme.spacing(0)} !important`}
            >
              <Typography fontWeight={400}>
                {i + 1}. {item.title}
              </Typography>
            </Box>
            <AccordionDetails>
              <Typography color='text.secondary' variant='body2'>
                {item.content}
              </Typography>
              {item.bullets && (
                <ol>
                  {item.bullets.map((bullet, index) => (
                    <Typography
                      key={index}
                      color='text.secondary'
                      variant='body2'
                      align='justify'
                    >
                      <li>{bullet}</li>
                    </Typography>
                  ))}
                </ol>
              )}

              {item.subcontent && (
                <Typography
                  color='text.secondary'
                  variant='body2'
                  align='justify'
                >
                  {item.subcontent}
                </Typography>
              )}
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

const Faq = () => {
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    // Group by category and include bullets and subcontent if they exist
    const result = Object.values(
      FAQJSON.reduce((acc, obj) => {
        const { id, title, content, category, bullets, subcontent } = obj;

        // If the category is not yet added, create it
        if (!acc[category]) {
          acc[category] = { title: category, items: [] };
        }

        // Prepare the item object with id, title, and content
        const item = { id, title, content };

        // Add bullets and subcontent if they exist
        if (bullets) item.bullets = bullets;
        if (subcontent) item.subcontent = subcontent;

        // Push the item into the correct category
        acc[category].items.push(item);

        return acc;
      }, {})
    );
    result.sort((a, b) => a.title.localeCompare(b.title));
    setTopics(result);
  }, []);

  return (
    <IconTemplate title='Help' image={HelpIcon}>
      {topics?.length > 0 &&
        topics.map((item, index) => (
          <Box marginBottom={6} key={index}>
            {' '}
            <FaqGroupItem title={item.title} items={item.items} />
          </Box>
        ))}
    </IconTemplate>
  );
};

export default Faq;
