import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const ConfirmModal = ({
  isOpen,
  setIsOpen,
  title,
  infoObject, // {key1: value1, key2: value2...}
  callback,
}) => {
  const theme = useTheme();

  const handleClose = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  }

  const handleCancel = (e) => {
    e.stopPropagation();
    callback(false);
  }

  const handleConfirm = (e) => {
    e.stopPropagation();
    callback(true);
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='confirmation-modal'
      aria-describedby='confirm-action'
    >
      <Box
        minWidth={theme.breakpoints.values.sm}
        justifyContent='center'
        alignItems='center'
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: `1px solid ${theme.palette.primary.dark}`,
          borderRadius: '8px',
          boxShadow: 12,
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card elevation={12} sx={{ width: '100%' }}>
          <CardHeader
            title={title}
            action={
              <Tooltip title='Close'>
                <IconButton aria-label='close' onClick={handleClose}>
                  <CancelRoundedIcon />
                </IconButton>
              </Tooltip>
            }
            sx={{ backgroundColor: theme.palette.primary.dark, color: 'white' }}
          />
          <Box sx={{ mt: 1 }}>
              <CardContent>
                <Grid
                  container
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  spacing={2}
                >
                  <Grid item>
                    {Object.entries(infoObject).map(([key, value], index) => {
                      return (
                        <TextField
                          fullWidth
                          disabled
                          margin='normal'
                          variant='filled'
                          key={index}
                          id={key}
                          label={key}
                          name={key}
                          value={value}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              </CardContent>
            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <Button onClick={handleCancel}>Cancel</Button>
              <Button variant='contained' onClick={handleConfirm}>
                Confirm
              </Button>
            </CardActions>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
