import React from 'react';
import {
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
  Checkbox,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ImageIcon from '@mui/icons-material/Image';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLocation } from 'react-router-dom';

const FMListItem = ({ index, file, openDeleteModal, openImageModal, handleCheckboxChange, isSelected, type }) => {
  const theme = useTheme();
  const location = useLocation();
  const isCriteria = location.pathname.includes('/criteria');
  const selectedView = type === 'selectedFiles';
  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        backgroundColor: theme.palette.grey[50],
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
          boxShadow: 8,
        },
      }}
    >
      <TableCell>{index}</TableCell>
      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 180,
        }}
      >
        <Tooltip title={file.name}>
          <Typography variant='body2'>{file.name}</Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        {file.isImage ? (
          <ImageIcon fontSize='small' />
        ) : (
          <TextSnippetIcon fontSize='small' />
        )}
      </TableCell>
      <TableCell>
        {(file.size / 1024).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </TableCell>
      <TableCell>
        {file?.created
          ? new Date(file.created).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
          : null}
      </TableCell>
      <TableCell>{file.status}</TableCell>
      {!selectedView && ( 
      <TableCell>
        {isCriteria ? (
          <Checkbox
            checked={isSelected}
            onChange={() => handleCheckboxChange(file)}
          />
        ) : (
          <>
            <Tooltip title='Delete file'>
              <DeleteIcon
                fontSize='small'
                sx={{ cursor: 'pointer' }}
                onClick={() => openDeleteModal(file)}
              />
            </Tooltip>
            {file.isImage && (
              <Tooltip title='View image'>
                <VisibilityIcon
                  fontSize='small'
                  sx={{ cursor: 'pointer', ml: 1 }}
                  onClick={() => openImageModal(file)}
                />
              </Tooltip>
            )}
          </>
        )}
      </TableCell>
      )}
    </TableRow>
  );
};

export default FMListItem;
