import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Avatar,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Container,
  Alert,
  InputAdornment,
  useTheme,
  IconButton,
} from '@mui/material';

import { AuthContext } from '../../shared/context/auth-context';

import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ResetModal from './components/ResetModal';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import LoadingButton from '../../shared/components/ui/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid format').required('Required'),
  password: Yup.string()
    .min(8, 'Must be atleast 8 characters')
    .required('Required'),
});

const LoginForm = () => {
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    // eslint-disable-next-line no-unused-vars
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resErrorMessage, setResErrorMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (formData) => {
    setResErrorMessage(null);
    setIsLoading(true);

    try {
      const recaptchaToken = await executeRecaptcha('login_form');

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/login`,
        { ...formData, recaptchaToken },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      auth.login(data.userId, data.token, data.user, undefined);
      navigate('/landing');
    } catch (error) {
      setResErrorMessage(
        error.response?.data.message || 'Unable to login. Please try again.'
      );
    }
    setIsLoading(false);
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <Box
          component='form'
          onSubmit={handleSubmit(handleLogin)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            {...register('email')}
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus
            {...(errors.email && {
              error: true,
              helperText: errors.email.message,
            })}
            variant='filled'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            {...register('password')}
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password (min 8 characters)'
            type={showPassword ? 'text' : 'password'}
            id='password'
            autoComplete='current-password'
            // placeholder="Minimum 8 characters"
            {...(errors.password && {
              error: true,
              helperText: errors.password.message,
            })}
            variant='filled'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PasswordIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                {...register('remember')}
                name='remember'
                id='remember'
                value='true'
                color='primary'
                variant='filled'
              />
            }
            label='Keep me logged in for a week'
          />

          {resErrorMessage && <Alert severity='error'>{resErrorMessage}</Alert>}

          <LoadingButton
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 2, mb: 2 }}
            isLoading={isLoading}
          >
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid
              item
              xs
              typography={theme.typography.body2}
              component={Link}
              onClick={() => setIsOpen(!isOpen)}
            >
              Forgot password?
            </Grid>
            <Grid
              item
              typography={theme.typography.body2}
              component={Link}
              to='/signup'
            >
              Don't have an account? Sign Up
            </Grid>
          </Grid>
        </Box>

        <ResetModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </Box>
    </Container>
  );
};

const Login = () => (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_CAPTCHA_PUBLIC_KEY}
  >
    <LoginForm />
  </GoogleReCaptchaProvider>
);

export default Login;
