import React from 'react';
import {
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Checkbox,
} from '@mui/material';

const OutlineListItem = ({ index, outline, handleCheckboxChange, isSelected, type }) => {
    const selectedView = type === 'selectedFiles';

  return (
    <TableRow>
      <TableCell>{index}</TableCell>
      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 180,
        }}
      >
        <Tooltip title={outline.name}>
          <Typography variant='body2'>{outline.name}</Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        {outline?.created
          ? new Date(outline.created).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
          : null}
      </TableCell>
      {!selectedView && (
      <TableCell>
        <Checkbox
          checked={isSelected}
          onChange={() => handleCheckboxChange(outline)}
        />
      </TableCell>
      )}
    </TableRow>
  );
};

export default OutlineListItem;