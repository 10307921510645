import React, { useState, useContext } from 'react';
import {
  Badge,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import ProjectUpdateModal from '../project/components/project/ProjectUpdateModal';
import ProjectDeleteModal from '../project/components/project/ProjectDeleteModal';
import { DataContext } from '../../shared/context/data-context';
import { overflowString } from '../../utils/helpers';
import { useNavigate } from 'react-router-dom';


const AssignmentItem = ({
  userId,
  assignment,
  handleSnackbarUpdate,
  handleProjectUpdate,
  handleProjectDelete,
}) => {
  const theme = useTheme();
  const dataContext = useContext(DataContext);

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const navigate = useNavigate();

  const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[400],
    },
  });

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card elevation={4}>
        <CardHeader
          title={
            <Tooltip title={assignment.name}>
              <Typography fontWeight={700}>
                {overflowString(assignment.name)}
              </Typography>
            </Tooltip>
          }
          subheader={
            <Typography variant='body2'>
              {new Date(assignment.created).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </Typography>
          }
          action={
            <Tooltip title='Edit Project Name/ Description'>
              <IconButton
                aria-label='edit'
                onClick={() => setIsUpdateModalOpen(!isUpdateModalOpen)}
              >
                <EditIcon color='primary' fontSize='small' />
              </IconButton>
            </Tooltip>
          }
          sx={{ backgroundColor: theme.palette.grey[200] }}
        />
        <CardContent
          sx={{
            height: 120,
            background: theme.palette.grey[100],
          }}
        >
          <Stack direction={'column'}>
            <Typography
              textAlign='center'
              variant='body2'
              color='text.secondary'
            >
              {assignment.description}
            </Typography>
            <br />
            <Typography
              textAlign='center'
              variant='caption'
              color='text.secondary'
            >
              {assignment.status}
            </Typography>
            <br />
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <Tooltip title='Manage Assignment Content'>
            <IconButton
              aria-label='upload'
              onClick={() => {
                dataContext.storeProject(assignment);
                navigate(`/files/${assignment.project}/assignments/${assignment._id}`);
              }}
            >
              <StyledBadge
                badgeContent={assignment?.files?.length}
              >
                <CloudUploadIcon color='primary' fontSize='small' />
              </StyledBadge>
            </IconButton>
          </Tooltip>
          <Tooltip title='Manage Assignment Criteria'>
            <IconButton
              aria-label='Criteria'
              onClick={() => {
                console.log('criteria');
              }}
            >
              <StyledBadge>
                <EditNoteIcon color='primary' fontSize='small' />
              </StyledBadge>
            </IconButton>
          </Tooltip>
          <Tooltip
            title='response'
          >
            <span>
              <IconButton
                aria-label='response'
                disabled={
                  assignment?.files?.length === 0 || assignment?.outlines?.length === 0
                }
                onClick={() => {
                  console.log('response');
                }}
              >
                <AutoAwesomeIcon
                  fontSize='small'
                  color={
                    assignment?.files?.length === 0 || assignment?.outlines?.length === 0
                      ? ''
                      : 'primary'
                  }
                />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              assignment?.jobs?.length === 0
                ? 'No generated content available to download'
                : 'Download Generated Content'
            }
          >
            <span>
              <IconButton
                aria-label='download'
                disabled={(assignment?.jobs?.length || 0) === 0 ? true : false}
                onClick={() => {
                  console.log('download');
                }}
              >
                <StyledBadge badgeContent={assignment?.jobs?.length}>
                  <DownloadIcon
                    fontSize='small'
                    color={(assignment?.jobs?.length || 0) === 0 ? '' : 'primary'}
                  />
                </StyledBadge>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title='Delete Assignment'>
            <IconButton
              aria-label='delete'
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <DeleteIcon sx={{ color: theme.palette.error.main }} fontSize='small' />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>

      <ProjectUpdateModal
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        project={assignment}
        handleSnackbarUpdate={handleSnackbarUpdate}
        handleProjectUpdate={handleProjectUpdate}
        type='assignments'
      />

      <ProjectDeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        project={assignment}
        handleSnackbarUpdate={handleSnackbarUpdate}
        handleProjectDelete={handleProjectDelete}
        type='assignments'
      />
    </Grid>
  );
};

export default AssignmentItem;
