import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
// import AutoStoriesIcon from '@mui/icons-material/AutoStories';
// import SummarizeIcon from '@mui/icons-material/Summarize';
// import GradingIcon from '@mui/icons-material/Grading';

const item = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	px: 2,
	height: '100%',
};

const Description = () => {
	return (
		<Box
			component='section'
			sx={{
				display: 'flex',
				overflow: 'hidden',
				bgcolor: '#eceff1',
				boxShadow: 3,
				width: '100vw',
				maxWidth: '100%',
			}}
		>
			<Container
				maxWidth={false}
				sx={{
					mt: 5,
					mb: 5,
					display: 'flex',
					position: 'relative',
					px: { xs: 2, sm: 5, md: 10 },
				}}
			>
				<Box
					component='img'
					src='/static/theme/appCurvyLines.png'
					alt='curvy lines'
					sx={{ pointerEvents: 'none', position: 'absolute', top: -180, right: '10%' }}
				/>

				<Grid container sx={{ width: '100%' }}>
					<Grid container item justifyContent="space-around">
						{[
							{
								title: "1. Curate Your Corpus",
								parenthetical: "(Optional, But Recommended)",
								text: "Gather PDFs or other documents to serve as a “source of truth” for your course. While not mandatory, having a corpus significantly boosts the accuracy and consistency of generated materials.",
								subtitle: "Why Use a Corpus?",
								subtexts: [
									"Provides a reference point for AI-generated text and assessments."
								]
							},
							{
								title: "2. Outline Your Modules",
								text: "Divide your course into clear modules or chapters. Think of these as the building blocks for your curriculum.",
								subtitle: "How It Helps",
								subtexts: [
									"Keeps your course organized, from introduction to advanced topics.",
									"Lays the groundwork for generating more specific content later."
								]
							},
							{
								title: "3. Add Images",
								text: "Upload relevant visuals to an image repository. Then integrate them into your module outlines where they fit best—whether it's in slides, written text, or summaries.",
								subtitle: "Benefits",
								subtexts: [
									"Provides a more engaging learning experience for students.",
									"Makes it easy to manage and reuse images across multiple modules."
								]
							},
							{
								title: "4. Create Your Content",
								text: "Produce a variety of materials for each module, leveraging any optional corpus for reference:",
								subtitle: "",
								subtexts: [
									"**Written Text:** Generate your own custom chapters.",
									"**Presentation Slides:** Download PowerPoint decks reaedy for use in class or further customization.",
									"**PDF Summaries:** Create concise reference sheet material for instructors demonstrating section output for text and bullet points. These are very handy for further modification of course outline for steering toward specific points.",
									"**Question Banks:** (coming soon) Create multiple-choice or short answer questions from your content or even how your content relates to articles, cases, or other content."
								]
							},
							{
								title: "5. Automatic Assessments",
								parenthetical: "(Coming Soon)",
								text: "Get ready to leverage AI for faster, more detailed student feedback.",
								subtitle: "",
								subtexts: [
									"**Short-Answer:** Automate feedback and scoring if you choose to base them on your modules and corpus and capture them in your LMS.",
									"**Free-Response & Presentations:** You'll still have full control over grading essays and other creative submissions, deciding how much feedback or scoring to share.",
									"**Future Updates:** Enhanced assessment tools for auto-grading. More robust analytics to track student progress and comprehension."
								]
							}
						].map((step, index) => (
							<Grid
								item
								xs={12}
								md={2.2}
								key={index}
								sx={{
									width: { md: '20%' }, // Force 5 columns on medium screens
									flexGrow: 0,
								}}
							>
								<Box sx={item}>
									<Typography variant='h5' sx={{ my: 2, whiteSpace: 'pre-line', fontSize: '1.4rem', mb: (index === 0 || index === 4) ? 0 : 2 }}>
										{step.title}
									</Typography>

									{step.parenthetical && (
										<Typography variant='h6' sx={{ fontSize: '1rem', color: 'text.secondary', mb: 1, mt: 0.5}}>
											{step.parenthetical}
										</Typography>
									)}
									<Typography sx={{ whiteSpace: 'pre-line' }}>
										{step.text}
									</Typography>
									
									{step.subtitle.length > 0 && (
										<Typography variant='h6' sx={{ mt: 3 }}>
											{step.subtitle}
										</Typography>
									)}
									{step.subtexts.map((subtext, index) => (
										<Typography
											key={index}
											sx={{
												whiteSpace: 'pre-line',
												display: 'block',
												mt: 1.5
											}}
										>
											{subtext.split('**').map((part, i) =>
												i % 2 === 0 ? part : <strong key={i}>{part}</strong>
											)}
										</Typography>
									))}
								</Box>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Container >
		</Box >
	);
};

export default Description;
