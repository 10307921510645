import React, { useContext, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { DataContext } from '../../shared/context/data-context';
import FMList from '../project/components/file/FMList';
import OutlineList from './components/OutLineList';
import AssigmentStepper from '../../shared/components/ui/AssignmentStepper';
import ThumbnailModal from '../../shared/components/ui/ThumbnailModal';
import TabPanel from '../../shared/components/ui/TabPanel';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';


const CriteriaPage = ({ setSnackbar }) => {
  const theme = useTheme();
  const [assignment, setAssignment] = useState();
  const dataContext = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedFiles, setLoadedFiles] = useState([]);
  const [outlines, setOutlines] = useState([]);
  const [selectedOutlines, setSelectedOutlines] = useState([]);
  const [isThumbnailModalOpen, setIsThumbnailModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    const getAssignment = dataContext.getProject();
    setAssignment(getAssignment);


    const fetchFiles = async () => {
      setIsLoading(true);
      //get corpus files
      const endpoint = `${process.env.REACT_APP_API_URL}/files/${assignment.project}`;
      try {
        const { data } = await axios.get(
          endpoint
        );
        setLoadedFiles(data.files);
      } catch (error) {
        console.log('error', error);
        setLoadedFiles([]);
        setSnackbarMessage(error.response?.data.message || 'Unable to retrieve files.');
        setSnackbar(true, error.response?.data.message || 'Unable to retrieve files.');
      }
      //get generated files
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/outlines/${assignment.project}`
        );
        setOutlines(data.outlines);
      } catch (error) {
        console.log('error', error);
        setLoadedFiles([]);
        setSnackbarMessage(error.response?.data.message || 'Unable to retrieve files.');
        setSnackbar(true, error.response?.data.message || 'Unable to retrieve files.');
      }
      setIsLoading(false);
    };

    if (assignment?.project) {
      fetchFiles();
    }

    // return () => {
    //   if (dataContext.getProject()?.name) {
    //     dataContext.storeProject(); // clean up
    //   }
    // };
  }, [dataContext, setSnackbar, assignment]);

  const handleCheckboxChange = (file) => {
    const isSelected = selectedFiles.includes(file);
    const newSelectedFiles = isSelected
      ? selectedFiles.filter((f) => f !== file)
      : [...selectedFiles, file];
    setSelectedFiles(newSelectedFiles);
  };

  const handleOutlineCheckboxChange = (outline) => {
      const isSelected = selectedOutlines.includes(outline);
      const newSelectedOutlines = isSelected
        ? selectedOutlines.filter((o) => o !== outline)
        : [...selectedOutlines, outline];
      setSelectedOutlines(newSelectedOutlines);
    };



  const openImageModal = (file) => {
    setImage(file);
    setIsThumbnailModalOpen(true);
  };

  const onCloseThumbnailModal = () => {
    setIsThumbnailModalOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: theme.palette.secondary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color='secondary' />
        </Backdrop>
      )}

      {/* <Typography variant='title'>Manage Project Files</Typography> */}
      (<AssigmentStepper project={assignment} activeStep={1} />)

      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={12}>
            {/* Panel 1: Files list for genreated content and Corpus cotent */}
            <Paper elevation={4} sx={{ p: 2, minHeight: '30vw' }}>
              <Typography
                textAlign='center'
                variant='body1'
                fontWeight={700}
                color='primary'
              >
                Choose Files For Grading Criteria
              </Typography>

              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label='files tabs'
                >
                  <Tab
                    label='Corpus Content'
                    icon={
                      isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <TextSnippetIcon fontSize='small' />
                      )
                    }
                    iconPosition='start'
                  />
                  <Tab
                    label='Generated Content'
                    icon={<AutoAwesomeIcon fontSize='small' />}
                    iconPosition='start'
                  />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                <FMList
                  files={loadedFiles}
                  type='text documents'
                  openImageModal={openImageModal}
                  error={loadedFiles?.length > 0 ? null : snackbarMessage}
                  selectedFiles={selectedFiles}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <OutlineList
                  outlines={outlines}
                  error={outlines?.length > 0 ? null : snackbarMessage}
                  selectedOutlines={selectedOutlines}
                  handleCheckboxChange={handleOutlineCheckboxChange}
                />
              </TabPanel>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {/* Panel 2: selected files */}
            <Paper elevation={4} sx={{ padding: 2, overflow: 'auto', maxHeight: '50vh' }}>
              <Typography
                textAlign='center'
                variant='body1'
                fontWeight={700}
                color='primary'
              >
                Selected Criteria
              </Typography>
              { (selectedFiles.length > 0 || selectedOutlines.length > 0) ? (
              <TabPanel value={0} index={0}>
                {selectedFiles.length > 0 && (
              <FMList
                files={selectedFiles}
                type='selectedFiles'
                openImageModal={openImageModal}
                error={selectedFiles?.length > 0 ? null : snackbarMessage}
              />
                )}
              <OutlineList
                  outlines={selectedOutlines}
                  error={outlines?.length > 0 ? null : snackbarMessage}
                  selectedOutlines={selectedOutlines}
                  type='selectedFiles'
                />
            </TabPanel>
            ) : (
              <Typography variant='body2' align='center'>
                No files selected.
              </Typography>
            )}
            </Paper>
          </Grid>
        </Grid>
      </Container>

      {image && (
        <ThumbnailModal
          isOpen={isThumbnailModalOpen}
          onClose={onCloseThumbnailModal}
          file={image}
        />
      )}
    </>
  );
};

export default CriteriaPage;
