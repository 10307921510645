import React, { useEffect, useRef, useState } from 'react';
import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';

const EditableText = ({
  fieldKey,
  text,
  onEdit,
  required = false,
  typoVariant = 'body2',
  textFieldVariant = 'standard',
  iconSize = 'small',
  numeric = false,
  rows = 3,
  sx = {},
}) => {
  const theme = useTheme();
  const typographyObject = theme.typography[typoVariant];
  const fontSize = typographyObject?.fontSize || '0.979rem';

  const [isEditing, setIsEditing] = useState(false);
  const [currentText, setCurrentText] = useState(text);
  const [tempText, setTempText] = useState(text);
  const [hasChanges, setHasChanges] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const textFieldRef = useRef(null);

  useEffect(() => {
    // Update local state if prop changes
    setCurrentText(text);
    setTempText(text);
  }, [text]);

  const handleTextClick = () => {
    setTempText(currentText); // Ensure temp text is current
    setIsEditing(true);

    // Set cursor at the end
    setTimeout(() => {
      if (textFieldRef.current) {
        const inputElement = textFieldRef.current;
        inputElement.focus();
        inputElement.setSelectionRange(
          currentText?.length,
          currentText?.length
        ); // Place cursor at the end
      }
    }, 0);
  };

  const handleSave = () => {
    if (required && tempText.trim() === '') {
      setError(true);
      setErrorMessage('Required');
      return;
    } else if (numeric && !/^\d*\.?\d*$/.test(tempText)) {
      setError(true);
      setErrorMessage('Numeric value expected');
      return;
    }

    setError(false);

    setIsEditing(false);
    if (hasChanges) {
      setCurrentText(tempText);
      onEdit(fieldKey, tempText); // Callback to parent
    }
    setHasChanges(false);
  };

  const handleCancel = () => {
    setTempText(currentText); // Discard changes
    setIsEditing(false);
    setHasChanges(false);
    setError(false);
  };

  const handleKeyDown = (event) => {
    // if (event.key === 'Enter') {
    //   handleSave();
    // } else
    if (event.key === 'Escape') {
      handleCancel();
    }
  };

  const handleChange = (event) => {
    setTempText(event.target.value);
    setHasChanges(event.target.value !== currentText); // Check for changes
  };

  const handleBlur = (event) => {
    const relatedTarget = event.relatedTarget;

    // Check if the next focused element is an IconButton
    if (
      relatedTarget &&
      (relatedTarget.getAttribute('data-role') === 'icon-button-cancel' ||
        relatedTarget.getAttribute('data-role') === 'icon-button-save')
    ) {
      return; // Prevent onBlur from executing
    }

    handleCancel();
  };

  return isEditing ? (
    <TextField
      fullWidth
      value={tempText}
      variant={textFieldVariant}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      error={error}
      helperText={error ? errorMessage : ''}
      multiline
      minRows={rows}
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position='end'>
              <IconButton
                data-role='icon-button-cancel'
                aria-label='cancel editing'
                onClick={handleCancel}
                edge='end'
                size={iconSize}
              >
                <CancelIcon fontSize={iconSize} color='error' />
              </IconButton>
            </InputAdornment>
            <InputAdornment position='end'>
              <IconButton
                data-role='icon-button-save'
                aria-label='save changes'
                onClick={handleSave}
                edge='end'
                size={iconSize}
              >
                <SaveIcon fontSize={iconSize} color='success' />
              </IconButton>
            </InputAdornment>
          </>
        ),
      }}
      inputRef={textFieldRef}
      autoFocus
      sx={{
        ...sx,
        '& .MuiInput-input': {
          cursor: 'text',
        },
        '& .MuiInputBase-input': {
          fontSize,
        },
      }}
    />
  ) : currentText || currentText === 0 ? (
    <Typography
      variant={typoVariant}
      onClick={handleTextClick}
      sx={{
        py: 1,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.05)',
        },
        whiteSpace: 'pre-line', // preserve line breaks
      }}
    >
      {currentText}
    </Typography>
  ) : (
    <IconButton
      aria-label='edit'
      onClick={handleTextClick}
      edge='end'
      size={iconSize}
    >
      <EditIcon fontSize={iconSize} />
    </IconButton>
  );
};

export default EditableText;
