import React from 'react';
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import OutlineListItem from './OutlineListItem';

const OutlineList = ({
  error,
  outlines,
  selectedOutlines,
  handleCheckboxChange,
  type,
}) => {
  const selectedView = type === 'selectedFiles';

  if (error || !outlines || outlines.length === 0) {
    return (
      <>
        <Typography variant='body2' align='center' sx={{ my: 2 }}>
          {error || 'No outlines in the project yet.'}
        </Typography>
      </>
    );
  } else {
    return (
      <Container>
        <Grid container justifyContent='center' spacing={2}>
          <Grid item xs={12}>
            <TableContainer sx={{ pt: 2 }}>
              <Table size='small' aria-label='outline table'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Outlines</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Created</TableCell>
                    {!selectedView && (
                      <TableCell sx={{ fontWeight: 'bold' }}>Select</TableCell>
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {outlines.map((outline, index) => (
                    <OutlineListItem
                      key={outline._id}
                      index={index + 1}
                      outline={outline}
                      handleCheckboxChange={handleCheckboxChange}
                      isSelected={selectedOutlines.includes(outline)}
                      type={type}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    );
  }
};

export default OutlineList;
