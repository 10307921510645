import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import LoadingButton from '../../../../shared/components/ui/LoadingButton';


const updateSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

const ProjectUpdateModal = ({
  isOpen,
  setIsOpen,
  project,
  handleSnackbarUpdate,
  handleProjectUpdate,
  type,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(false);

  // eslint-disable-next-line
  const [isAssignment, setIsAssignment] = useState(type === 'assignments');


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { name: project.name, description: project.description },
    resolver: yupResolver(updateSchema),
  });

  const handleEdit = React.useCallback(
    async (formData) => {
      setIsLoading(true);

      try {
        if (isAssignment) {
          // eslint-disable-next-line
          const { data } = await axios.patch(
            `${process.env.REACT_APP_API_URL}/projects/${project.project}/assignments/${project._id}`,
            formData,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          setIsLoading(false);
          handleProjectUpdate(project._id, formData.name, formData.description);
          handleSnackbarUpdate('Assignment details updated.');
        } else {
          // eslint-disable-next-line
          const { data } = await axios.patch(
            `${process.env.REACT_APP_API_URL}/projects/${project.id}`,
            formData,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          setIsLoading(false);
          handleProjectUpdate(project.id, formData.name, formData.description);
          handleSnackbarUpdate('Project details updated.');
        }
      } catch (error) {
        setIsLoading(false);
        handleSnackbarUpdate(
          error.response?.data.message ||
          'Unable to update project. Please try again.'
        );
      }
      setIsOpen(false);
    },

     // eslint-disable-next-line
    [handleProjectUpdate, handleSnackbarUpdate, setIsOpen, project.id]
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        minWidth={theme.breakpoints.values.sm}
        justifyContent='center'
        alignItems='center'
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: `1px solid ${theme.palette.primary.dark}`,
          borderRadius: '8px',
          boxShadow: 12,
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card elevation={12} sx={{ width: '100%' }}>
          <CardHeader
            title={isAssignment ? 'Edit Assignment Information' : 'Edit Project Information'}
            action={
              <Tooltip title='Close'>
                <IconButton
                  aria-label='close'
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <CancelRoundedIcon />
                </IconButton>
              </Tooltip>
            }
            sx={{ backgroundColor: theme.palette.primary.dark, color: 'white' }}
          />
          <Box
            component='form'
            onSubmit={handleSubmit(handleEdit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <CardContent>
              <Typography
                textAlign='center'
                variant='body2'
                color='text.secondary'
              >
                Project created:{' '}
                {new Date(project.created).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </Typography>
              <Grid
                container
                direction='column'
                alignItems='center'
                justifyContent='center'
                spacing={2}
              >
                <Grid item>
                  <TextField
                    {...register('name')}
                    margin='normal'
                    required
                    fullWidth
                    id='name'
                    label={isAssignment ? 'Assignment Name' : 'Project Name'}
                    name='name'
                    autoFocus
                    {...(errors.name && {
                      error: true,
                      helperText: errors.name.message,
                    })}
                    variant='filled'
                  />

                  <TextField
                    {...register('description')}
                    margin='normal'
                    required
                    fullWidth
                    id='description'
                    label={isAssignment ? 'Assignment Description' : 'Project Description'}
                    name='description'
                    {...(errors.description && {
                      error: true,
                      helperText: errors.description.message,
                    })}
                    variant='filled'
                  />
                  {isAssignment && (
                      <FormControl fullWidth variant="filled" margin="normal">
                        <InputLabel id="response-type-label">Assignment Type</InputLabel>
                        <Select
                          labelId="response-type-label"
                          id="response-type"
                          defaultValue="short-answer"
                          fullWidth
                          {...register('type')}
                        >
                          <MenuItem value="short-answer">Short Answer</MenuItem>
                          <MenuItem value="structured-response" disabled>
                            Structured Response
                          </MenuItem>
                          <MenuItem value="presentation" disabled>
                            Presentation
                          </MenuItem>
                        </Select>
                      </FormControl>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
              <Button onClick={() => reset()}>Reset</Button>
              <LoadingButton
                type='submit'
                isLoading={isLoading}
                variant='contained'
              >
                Update
              </LoadingButton>
            </CardActions>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};

export default ProjectUpdateModal;
