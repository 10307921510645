import React from 'react';
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

const OutlineTable = ({ job, handleOutlineSelection }) => {
  const theme = useTheme();

  if (!job) {
    return (
      <Container maxWidth='lg' sx={{ mt: 2, mb: 2 }}>
        <Grid container justifyContent='center'>
          <Grid item xs={12}>
            <Typography textAlign='center' variant='body2'>
              Select a content item
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Section</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Prompt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {job?.content?.generated?.map((row, index) => {
            return (
              <TableRow
                key={row.seq}
                onClick={() => handleOutlineSelection(index)}
                sx={{
                  cursor: 'pointer',
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: theme.palette.grey[50],
                  '&:hover': {
                    backgroundColor: theme.palette.grey[100],
                    boxShadow: 8,
                  },
                }}
              >
                <TableCell>{row.seq}</TableCell>
                <TableCell>{row.section}</TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.section === 'image' ? 'Image Object' : row.prompt}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OutlineTable;
