import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { capitalizeFirstLetter } from '../../../utils/helpers';

const ContentHeader = ({ seq, section, title, prompt }) => {
  return (
    <Container maxWidth='md' sx={{ mt: 1, mb: 1 }}>
      <Grid
        container
        spacing={2}
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <Grid item xs={12}>
          <Typography variant='body2' fontWeight='bold'>
            {section !== 'image' && (
              <>{seq}.</>
            )} {capitalizeFirstLetter(section)}: {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {section !== 'image' && (
            <Typography variant='body2' fontWeight={600}>
              {prompt}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContentHeader;
