
import { Box } from '@mui/material';
import ListItemLeft from './ListItemLeft';
import ListItemRight from './ListItemRight';
import ListItemCenter from './ListItemCenter';
import { Draggable } from '@hello-pangea/dnd';

const OutlineListItem = ({
	item,
	index,
	config = {
		expandedItems: [],
		hasChildren: false,
	},
	handlers = {
		toggleExpand: () => { },
		deleteHandler: () => { },
		addItemHandler: () => { },
		addImageHandler: () => { },
		previewImageHandler: () => { },
		handleItemUpdate: () => { }
	}
}) => {

	const isExpanded = config.expandedItems.includes(item.seq);

	const itemMargin = {
		topic: 0,
		subtopic: 30,
		body: 60,
		definition: 60,
		image: 60,
	}

	return (
		<Draggable key={item.seq.toString()} draggableId={item.seq.toString()} index={index} >
			{(provided) => (
				<Box
					ref={provided.innerRef}

					{...provided.draggableProps}

					onClick={() => {
						if (item.section === 'image') {
							handlers.previewImageHandler(item);
						} else {
							handlers.toggleExpand(item.seq);
						}

					}}

					boxShadow={2}

					sx={{
						mt: item.section === 'topic' ? 2 : 0.5,
						mb: 0.5,

						display: 'flex',
						alignItems: 'center',
						borderRadius: '4px',

						'&:hover': {
							boxShadow: 'none',
							backgroundColor: 'rgba(0, 0, 0, 0.04)'
						},
						marginTop: item.section === 'topic' ? 1 : 0.15,
						marginLeft: `${itemMargin[item.section]}px`,
						padding: '10px',
						border: '1px solid #666',

						'&:hover .delete-icon': {
							visibility: 'visible'
						},
					}}
				>
					{/* Icon Button & Drag Icon Section */}
					<ListItemLeft
						section={item.section}
						dragHandleProps={provided.dragHandleProps}
						addItemHandler={handlers.addItemHandler}
						itemID={item.seq}
						addImageHandler={handlers.addImageHandler}
					/>

					{/* Title and prompt Section */}
					<ListItemCenter
						seq={item.seq}
						title={item.title}
						prompt={item.prompt}
						section={item.section}
						handleItemUpdate={handlers.handleItemUpdate}
					/>

					{/* Action Section */}
					<ListItemRight
						section={item.section}
						title={item.title}
						prompt={item.prompt}
						hasChildren={config.hasChildren}
						isExpanded={isExpanded}
						onToggle={() => handlers.toggleExpand(item.seq)}
						deleteHandler={handlers.deleteHandler}
					/>
				</Box>
			)}
		</Draggable>
	);
};

export default OutlineListItem;
