import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../shared/context/auth-context';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import IconTemplate from '../public/components/IconTemplate';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EditableTextField from './components/EditableTextField';
import { validateEmail } from '../../utils/helpers';

const UserSettings = ({ setSnackbar }) => {
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const [fn, setFn] = useState('');
  const [ln, setLn] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (auth.user) {
      setFn(auth.user.firstname);
      setLn(auth.user.lastname);
      setEmail(auth.user.email);
    }
  }, [auth]);

  const handleSnackbarUpdate = useCallback((message) => {
    setSnackbar(true, message);
  }, [setSnackbar]);

  // Update user settings
  const onSave = async (label, newValue) => {
    let oldValue = ''; // revert id there's an error
    const postData = {};
    if (label === 'First Name') {
      oldValue = fn;
      postData['firstname'] = newValue;
      setFn(newValue);
    } else if (label === 'Last Name') {
      oldValue = ln;
      postData['lastname'] = newValue;
      setLn(newValue);
    } else if (label === 'email') {
      oldValue = email;
      postData['email'] = newValue;
      setEmail(newValue);
    }

    if (Object.keys(postData).length > 0) {
      setIsLoading(true);
      try {
        await axios.patch(
          `${process.env.REACT_APP_API_URL}/users/${auth.userId}`,
          postData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        handleSnackbarUpdate(
          'Cache updated! Remember to logout & login for the change to be applied.'
        );
        // console.log(data);
      } catch (error) {
        handleSnackbarUpdate(
          error.response?.data.message || 'Unable to update user data.'
        );

        if (label === 'First Name') {
          setFn(oldValue);
        } else if (label === 'Last Name') {
          setLn(oldValue);
        } else if (label === 'email') {
          setEmail(oldValue);
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: theme.palette.secondary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color='secondary' />
        </Backdrop>
      )}

      <IconTemplate
        title='User Settings'
        image={ManageAccountsIcon}
        maxWidth='xs'
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {auth.user && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <Alert severity='warning' variant='filled'>
                    You can change these values but updates will only be applied
                    the next time you login.
                  </Alert>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <Typography variant='smallerFontBold' textAlign='center'>
                    Reference:&nbsp;
                  </Typography>
                  <Typography variant='smallerFont' textAlign='center'>
                    {auth?.userId}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <EditableTextField
                  label='First Name'
                  value={fn}
                  onSave={onSave}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableTextField
                  label='Last Name'
                  value={ln}
                  onSave={onSave}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableTextField
                  label='email'
                  value={email}
                  onSave={onSave}
                  validationFn={validateEmail}
                  validationMessage='Invalid email address'
                />
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant='contained'
                  LinkComponent={Link}
                  to='/login'
                  sx={{ mb: 1 }}
                >
                  Reset Password
                </Button>
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <Typography fontSize={12}>
                    Use the password reset link on the login page
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </IconTemplate>
    </>
  );
};

export default UserSettings;
