import React, { useCallback, useState } from 'react';
import axios from 'axios';
import {
  Tabs,
  Tab,
  Box,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { AutoFixHigh as AutoGenerateIcon } from '@mui/icons-material';

const toolTips = [
  '',
  'Generate sample course objective based on the topic',
  '',
  '',
  '',
];

const tabNames = [
  '1. Topic',
  '2. Objectives',
  '3. Ideas',
  '4. Modules',
  '5. Generate Outline',
];
const labels = [
  'Topic',
  'Course objectives',
  'Initial ideas',
  'Number of teaching modules',
  'Outline',
];
const placeholders = [
  'What is the main topic for which you would like to create the outline?',
  'Describe the course objective in as much detail as you would like. These can be expressed as learning outcomes or just a general description of the course.',
  'Are you able to provide an initial set of topics that you would like to cover? It is typically helpful to have a starting point from which you can begin.',
  'Do you have a total number of teaching modules in mind ? These are analogous to chapters of a book; where the book is the topic. Modules can be as short as one lecture, or can have enough detail that they span multiple weeks. As a rule of thumb most undergrad formats would have a module:week ratio of 3:4.  Most grad classes would have 1:1 ratio',
  'Define outline',
];

const rowCounts = [1, 10, 10, 1, 10];

const TabForm = ({ auth, setIsLoading, showSnackbarMessage }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState(Array(5).fill(''));
  const [tabEnabled, setTabEnabled] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);
  const [errors, setErrors] = useState(Array(5).fill(false));

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = (index) => {
    console.log(formData[index]);
    if (formData[index]?.trim() === '') {
      setErrors((prev) => {
        const updated = [...prev];
        updated[index] = true;
        return updated;
      });

      // disable all successive tabs if any tab is cleared
      setTabEnabled((prev) => {
        const updated = [...prev];
        for (let i = index; i < updated.length - 1; i++) {
          updated[i + 1] = false;
        }
        return updated;
      });
    } else {
      // clear any current errors
      setErrors((prev) => {
        const updated = [...prev];
        updated[index] = false;
        return updated;
      });

      // activate the next tab
      if (index < 4) {
        setTabEnabled((prev) => {
          const updated = [...prev];
          updated[index + 1] = true;
          return updated;
        });
        setActiveTab(index + 1);
      }
    }
  };

  const handleSave = () => {
    console.log('Form data saved:', formData);
  };

  const handleInputChange = useCallback(
    (index, value) => {
      setFormData((prev) => {
        const updated = [...prev];
        updated[index] = value;
        return updated;
      });
      setErrors((prev) => {
        const updated = [...prev];
        updated[index] = false;
        return updated;
      });
    },
    [setFormData, setErrors]
  );

  const chat2 = async (index, tag, vars) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/ai/${tag}`,
        {
          vars,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      setFormData((prev) => {
        const updated = [...prev];
        updated[index] = data.content;
        return updated;
      });
    } catch (error) {
      showSnackbarMessage(
        error.response?.data.message || 'Unable to complete the request.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAutoGenerate = async (index) => {
    console.log(`index: ${index}`);
    console.log(`tab: ${tabNames[index]}`);
    console.log(`previous field val: ${formData[index - 1]}`);
    // Implement your auto-generate logic here
    switch (index) {
      case 1: // 2nd tab
        await chat2(index, 'oh-course-objective', {
          mainTopic: formData[index - 1],
        });
        break;
      case 2: // 3rd tab
        await chat2(index, 'oh-initial-ideas', {
          mainTopic: formData[index - 2],
          mainObjectives: formData[index - 1],
        });
        break;
      case 3: // 3rd tab
        setFormData((prev) => {
          const updated = [...prev];
          updated[index] = '9';
          return updated;
        });
        break;
      default:
        break;
    }

    setErrors((prev) => {
      const updated = [...prev];
      updated[index] = false;
      return updated;
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
      >
        {tabNames.map((name, index) => (
          <Tab key={index} label={name} disabled={!tabEnabled[index]} />
        ))}
      </Tabs>
      {tabNames.map((name, index) => (
        <Box
          key={index}
          role='tabpanel'
          hidden={activeTab !== index}
          sx={{ mt: 2 }}
        >
          {activeTab === index && (
            <>
              {/* <Typography variant='h6' gutterBottom>
                {`Instructions for ${name}`}
              </Typography> */}
              {/* <Typography variant='body2' gutterBottom>
                {index > 0 &&
                  `You can reference previous inputs: ${formData
                    .slice(0, index)
                    .join(', ')}`}
              </Typography> */}
              <Typography variant='body2' sx={{ mt: 2 }}>
                {placeholders[index]}
              </Typography>
              <TextField
                fullWidth
                label={labels[index]}
                value={formData[index]}
                onChange={(e) => handleInputChange(index, e.target.value)}
                error={errors[index]}
                helperText={errors[index] ? 'This field is required' : ''}
                multiline
                minRows={rowCounts[index]}
                sx={{ mt: 2 }}
                InputProps={
                  index > 0
                    ? {
                        endAdornment: (
                          <Tooltip title={toolTips[index]}>
                            <IconButton
                              size='small'
                              onClick={() => handleAutoGenerate(index)}
                            >
                              <AutoGenerateIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        ),
                      }
                    : undefined
                }
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
                {index < 4 ? (
                  <Button variant='contained' onClick={() => handleNext(index)}>
                    Next
                  </Button>
                ) : (
                  <Button variant='contained' onClick={handleSave}>
                    Save
                  </Button>
                )}
              </Box>
            </>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default TabForm;
