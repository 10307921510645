import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
// import { blue, purple } from '@mui/material/colors';
// import theme from '../../shared/theme/Theme';

const Banner = ({ theme }) => {
  // const theme = createTheme();

  return (
    <Box
      sx={{
        // bgcolor: 'background.paper',
        // bgcolor: 'primary.light',
        background: "linear-gradient(#1565c0, #e3f2fd)",
        // bgcolor: blue[200],
        // bgcolor: purple[200],
        marginTop: "-1em",
        pt: 8,
        pb: 2,
      }}
    >
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.secondary"
          gutterBottom
        >
          CorpusKey
        </Typography>
        <Typography variant="h5" align="center" color="white">
          Create custom courses from curated content. A new approach to engagement and personalized learning.
        </Typography>

        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          sx={{ pt: 3 }}
        >
          Develop the course you want with the material you've chosen. CorpusKey
          helps you generate student readings, develops all the necessary
          complements, and reduces your grading. Monitor student progress and adapt the course along the way.
        </Typography>
        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          {/* <Button variant='contained'>Main call to action</Button>
              <Button variant='outlined'>Secondary action</Button> */}
        </Stack>
      </Container>
    </Box>
  );
};

export default Banner;
