import React, { useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import {
	Add as AddIcon,
	DragIndicator as DragIndicatorIcon,
	Image as ImageIcon
} from '@mui/icons-material';

const options = {
	topic: [
		{ label: 'Topic', value: 'topic' },
		{ label: 'SubTopic', value: 'subtopic' },
		{ label: 'Image', value: 'image' },
	],
	subtopic: [
		{ label: 'SubTopic', value: 'subtopic' },
		{ label: 'Body', value: 'body' },
		{ label: 'Image', value: 'image' },
	],
	body: [
		{ label: 'Body', value: 'body' },
		{ label: 'Definition', value: 'definition' },
		{ label: 'Image', value: 'image' },
	],
	definition: [
		{ label: 'Body', value: 'body' },
		{ label: 'Definition', value: 'definition' },
		{ label: 'Image', value: 'image' },
	],
};

const ListItemLeft = ({ section, addItemHandler, dragHandleProps, itemID, addImageHandler }) => {
	const [menuAnchor, setMenuAnchor] = useState(null);
	const handleMenuOpen = (event) => setMenuAnchor(event.currentTarget);
	const handleMenuClose = () => setMenuAnchor(null);

	const menuOptions = options[section] || [];

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			{/* add item */}
			{section !== 'image' ? (
				<>
					<Tooltip title='Add Section'>
						<IconButton size='small' onClick={(e) => { e.stopPropagation(); handleMenuOpen(e); }}>
							<AddIcon />
						</IconButton>
					</Tooltip>

					<Menu
						anchorEl={menuAnchor}
						open={Boolean(menuAnchor)}
						onClose={(e) => { e.stopPropagation(); handleMenuClose(); }}
					>
						{menuOptions.map((item, index) => (
							<MenuItem key={index} onClick={(e) => {
								e.stopPropagation();
								item.value === 'image' ? addImageHandler(itemID) : addItemHandler(item.value, itemID);
								handleMenuClose();
							}}>
								{item.label}
							</MenuItem>
						))}
					</Menu>
				</>
			) : (
				<Box sx={{ width: 34 }} />
			)}

			{/* item type indication */}
			<Typography sx={{ 
				marginLeft: '4px', 
				color: 'gray', 
				fontSize: '0.65rem',
				width: '50px',
				display: 'inline-block',
				textAlign: 'center',
			}}>
				{section.toUpperCase()}
			</Typography>
			
			{/* drag handle */}
			<Box 
				{...dragHandleProps} 
				sx={{ 
					marginLeft: '4px', 
					// display: section === 'image' ? 'none' : 'flex' 
				}}>
				
				<DragIndicatorIcon sx={{ cursor: section === 'image' ? 'pointer' : 'grab' }} />
			</Box>

			{/* image icon */}
			{section === 'image' ? <ImageIcon /> : null}

		</Box>
	);
};

export default ListItemLeft;
