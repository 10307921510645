import React, { useEffect, useState } from 'react';
import {
  Typography,
  InputAdornment,
  IconButton,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';

const EditableDropdown = ({
  fieldKey,
  text,
  options,
  onEdit,
  required = false,
  typoVariant = 'body2',
  selectVariant = 'standard',
  iconSize = 'small',
  sx = {},
}) => {
  const theme = useTheme();
  const typographyObject = theme.typography[typoVariant];
  const fontSize = typographyObject?.fontSize || '0.979rem';

  const [isEditing, setIsEditing] = useState(false);
  const [currentText, setCurrentText] = useState(text);
  const [tempText, setTempText] = useState(text);
  const [hasChanges, setHasChanges] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(true); // Initially open

  useEffect(() => {
    // Update local state if prop changes
    setCurrentText(text);
    setTempText(text);
  }, [text]);

  useEffect(() => {
    setOpen(true);
  }, [isEditing]);

  const handleTextClick = () => {
    setTempText(currentText); // Ensure temp text is current
    setIsEditing(true);
  };

  const handleSave = () => {
    if (required && tempText.trim() === '') {
      setError(true);
      return;
    }
    setError(false);

    setIsEditing(false);
    if (hasChanges) {
      setCurrentText(tempText);
      onEdit(fieldKey, tempText); // Callback to parent
    }
    setHasChanges(false);
  };

  const handleCancel = () => {
    setTempText(currentText); // Discard changes
    setIsEditing(false);
    setHasChanges(false);
    setError(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    } else if (event.key === 'Escape') {
      handleCancel();
    }
  };

  const handleChange = (event) => {
    setTempText(event.target.value);
    setHasChanges(event.target.value !== currentText); // Check for changes
  };

  const handleBlur = (event) => {
    const relatedTarget = event.relatedTarget;

    // Check if the next focused element is an IconButton
    if (
      relatedTarget &&
      (relatedTarget.getAttribute('data-role') === 'icon-button-cancel' ||
        relatedTarget.getAttribute('data-role') === 'icon-button-save')
    ) {
      return; // Prevent onBlur from executing
    }

    handleCancel();
  };

  return isEditing ? (
    <FormControl fullWidth>
      <Select
        value={tempText}
        variant={selectVariant}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        autoFocus
        renderValue={(value) => value}
        endAdornment={
          <>
            <InputAdornment position='end'>
              <IconButton
                data-role='icon-button-cancel'
                aria-label='cancel editing'
                onClick={handleCancel}
                edge='end'
                size={iconSize}
              >
                <CancelIcon fontSize={iconSize} color='error' />
              </IconButton>
            </InputAdornment>
            <InputAdornment position='end'>
              <IconButton
                data-role='icon-button-save'
                aria-label='save changes'
                onClick={handleSave}
                edge='end'
                size={iconSize}
              >
                <SaveIcon fontSize={iconSize} color='success' />
              </IconButton>
            </InputAdornment>
          </>
        }
        sx={{
          ...sx,
          '& .MuiInputBase-input': {
            fontSize,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>Required</FormHelperText>}
    </FormControl>
  ) : currentText || currentText === 0 ? (
    <Typography
      variant={typoVariant}
      onClick={handleTextClick}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.05)',
        },
      }}
    >
      {currentText}
    </Typography>
  ) : (
    <IconButton
      aria-label='edit'
      onClick={handleTextClick}
      edge='end'
      size={iconSize}
    >
      <EditIcon fontSize={iconSize} />
    </IconButton>
  );
};

export default EditableDropdown;
