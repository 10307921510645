import React, { useState } from 'react';
import { Box } from '@mui/material';
import OutlineList from './OutlineList';
import OutlinePageMRT from './OutlinePageMRT';

const OutlinePage = ({ setSnackbar }) => {
	const [newVersion, setNewVersion] = useState(true);

	return (
		<Box>
		{newVersion ? (
			<OutlineList
				setSnackbar={setSnackbar}
				newVersion={newVersion}
				setNewVersion={setNewVersion}
			/>
		) : (
			<OutlinePageMRT
				setSnackbar={setSnackbar}
				newVersion={newVersion}
				setNewVersion={setNewVersion}
			/>
		)}
		</Box>
	);
};

export default OutlinePage;