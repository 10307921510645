import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Paper,
  SpeedDial,
  SpeedDialAction,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import EditableText from '../../../shared/components/ui/EditableText';
import EditableDropdown from '../../../shared/components/ui/EditableDropdown';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PromptNew from './PromptNew';
import CopyToClipboard from '../../../shared/components/ui/CopyToClipboard';
import EditIcon from '@mui/icons-material/Edit';

const cellStyling = {
  fontWeight: 500,
};

const llmOptions = [
  { label: 'Open AI', value: 'OPENAI' },
  { label: 'Gemini', value: 'GEMINI' },
];

const llmModelOptions = {
  OPENAI: [
    { label: 'Server Default', value: '' },
    { label: 'GPT-3.5', value: 'gpt-3.5-turbo' },
    { label: 'GPT-4o Mini', value: 'gpt-4o-mini' },
    { label: 'GPT-4', value: 'gpt-4' },
  ],
  GEMINI: [
    { label: 'Server Default', value: '' },
    { label: 'Flash-1.5', value: 'gemini-1.5-flash' },
  ],
};

const Prompts = ({ authToken, setIsLoading }) => {
  const [consumers, setConsumers] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [prompt, setPrompt] = useState(null);
  const [error, setError] = useState('');
  const [openCreateForm, setOpenCreateForm] = useState(false);

  const fetchPromptStats = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/prompts/count/consumer`
      );
      setConsumers(data.stats);
    } catch (error) {
      setError(error.response?.data.message || 'Error processing request.');
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setConsumers, setError]);

  useEffect(() => {
    fetchPromptStats();
  }, [fetchPromptStats]);

  const onConsumerSelection = (consumer) => {
    setOpenCreateForm(false);
    const fetchPrompts = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/prompts/search/${consumer}`
        );
        setPrompts(data.prompts);
        setPrompt(null);
      } catch (error) {
        setError(error.response?.data.message || 'Error processing request.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrompts();
  };

  const onPromptSelection = (tag) => {
    setOpenCreateForm(false);
    const fetchPromptByTag = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/prompts/${tag}`
        );
        setPrompt(data.prompt);
      } catch (error) {
        setError(error.response?.data.message || 'Error processing request.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPromptByTag();
  };

  const handleNewPromptCreation = (formData, clearForm) => {
    const params = {};
    for (const key in formData) {
      if (
        formData[key] === null ||
        formData[key] === undefined ||
        formData[key] === ''
      ) {
        delete formData[key];
      } else if (key === 'llm') {
        params['ai'] = formData['llm'];
        delete formData['llm'];
      } else if (key === 'model') {
        params['model'] = formData['model'];
        delete formData['model'];
      } else if (key === 'temperature') {
        params['temperature'] = formData['temperature'];
        delete formData['temperature'];
      } else if (key === 'penalty') {
        params['penalty'] = formData['penalty'];
        delete formData['penalty'];
      } else if (key === 'maxTokens') {
        params['maxTokens'] = formData['maxTokens'];
        delete formData['maxTokens'];
      }
    }
    formData['params'] = params;

    const createPrompt = async () => {
      setError('');
      try {
        setIsLoading(true);
        // eslint-disable-next-line
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/prompts`,
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        clearForm();
        setOpenCreateForm(false);
        setPrompts([]);
        fetchPromptStats();
      } catch (error) {
        setError(error.response?.data.message || 'Error processing request.');
      } finally {
        setIsLoading(false);
      }
    };

    createPrompt();
  };

  const onEdit = (fieldKey, updatedValue) => {
    if (!prompt || !prompt.tag) {
      setError('Something went wrong. Unable to update.');
      return;
    }

    let payload = {};
    if (fieldKey.startsWith('params.')) {
      if (fieldKey === 'params.ai') {
        payload = { params: { ai: updatedValue, model: '' } };
      } else {
        payload = {
          params: {
            [fieldKey.substring(fieldKey.indexOf('.') + 1)]: updatedValue,
          },
        };
      }
    } else {
      payload = { [fieldKey]: updatedValue };
    }

    const patchPrompt = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.patch(
          `${process.env.REACT_APP_API_URL}/prompts/${prompt.tag}`,
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        setPrompt(data.prompt);
      } catch (error) {
        setError(error.response?.data.message || 'Error processing request.');
      } finally {
        setIsLoading(false);
      }
    };

    // console.log(payload);
    patchPrompt();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant='body1' fontWeight={700} sx={{ mb: 1 }}>
          Prompts
        </Typography>
      </Box>

      {error ? (
        <Alert severity='warning'>{error}</Alert>
      ) : consumers?.length > 0 ? (
        <Grid container spacing={2}>
          {/* Left nav */}
          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                minHeight: '320px',
                maxHeight: '320px',
                overflow: 'auto',
              }}
            >
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Consumer</TableCell>
                      <TableCell>Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {consumers?.map((consumer, index) => (
                      <TableRow
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => onConsumerSelection(consumer.consumer)}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{consumer.consumer}</TableCell>
                        <TableCell>{consumer.count}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            {/* Prompts list */}
            <Paper
              sx={{
                minHeight: '320px',
                maxHeight: '320px',
                overflow: 'auto',
              }}
            >
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Tag</TableCell>
                      <TableCell>LLM</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {prompts?.map((prompt, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{prompt.tag}</TableCell>
                        <TableCell>{prompt?.params?.ai}</TableCell>
                        <TableCell>
                          {new Date(prompt.created).toLocaleDateString(
                            'en-US',
                            {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            }
                          )}
                        </TableCell>
                        <TableCell>
                          <CopyToClipboard text={prompt.tag} />
                          <Tooltip title='Edit'>
                            <IconButton
                              onClick={() => onPromptSelection(prompt.tag)}
                              aria-label='edit'
                              size='small'
                            >
                              <EditIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Typography textAlign='center'>
          No records available for consumers
        </Typography>
      )}

      {/* bottom edit prompt table */}
      {prompt && (
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          width: 120,
                          fontWeight: 600,
                        }}
                      >
                        Attribute
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ ...cellStyling }}>Consumer</TableCell>
                      <TableCell>{prompt.consumer}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...cellStyling }}>Tag</TableCell>
                      <TableCell>{prompt.tag}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...cellStyling }}>Created</TableCell>
                      <TableCell>
                        {new Date(prompt.created).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...cellStyling }}>
                        System Role
                        <br />
                        <Typography variant='caption' color='red'>
                          Required
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <EditableText
                          fieldKey={'systemRole'}
                          text={prompt.systemRole}
                          onEdit={onEdit}
                          required
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...cellStyling }}>
                        User Role
                        <br />
                        <Typography variant='caption' color='red'>
                          Required
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <EditableText
                          fieldKey={'userRole'}
                          text={prompt.userRole}
                          onEdit={onEdit}
                          required
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...cellStyling }}>
                        LLM
                        <br />
                        <Typography variant='caption' color='red'>
                          Required
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <EditableDropdown
                          fieldKey='params.ai'
                          text={prompt?.params?.ai}
                          onEdit={onEdit}
                          options={llmOptions}
                          required
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...cellStyling }}>
                        Model
                        <br />
                        <Typography variant='caption' color='gray'>
                          Optional
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <EditableDropdown
                          fieldKey={'params.model'}
                          text={prompt?.params?.model || ''}
                          onEdit={onEdit}
                          options={llmModelOptions[prompt?.params?.ai]}
                          rows={1}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...cellStyling }}>
                        Temperature
                        <br />
                        <Typography variant='caption' color='gray'>
                          Optional
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <EditableText
                          fieldKey={'params.temperature'}
                          text={prompt?.params?.temperature}
                          onEdit={onEdit}
                          rows={1}
                          numeric
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...cellStyling }}>
                        Penalty
                        <br />
                        <Typography variant='caption' color='gray'>
                          Optional
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <EditableText
                          fieldKey={'params.penalty'}
                          text={prompt?.params?.penalty}
                          onEdit={onEdit}
                          rows={1}
                          numeric
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ ...cellStyling }}>
                        Max Tokens
                        <br />
                        <Typography variant='caption' color='gray'>
                          Optional
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <EditableText
                          fieldKey={'params.maxTokens'}
                          text={prompt?.params?.maxTokens}
                          onEdit={onEdit}
                          rows={1}
                          numeric
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* bottom edit prompt table */}
      {openCreateForm && (
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Paper>
              <PromptNew
                llmOptions={llmModelOptions}
                handleNewPromptCreation={handleNewPromptCreation}
              />
            </Paper>
          </Grid>
        </Grid>
      )}

      <SpeedDial
        ariaLabel='SpeedDial create prompt'
        sx={{
          position: 'fixed',
          bottom: 72,
          right: 16,
          '& .MuiFab-root': {
            width: 32, // Adjust size
            height: 32,
            minHeight: 32,
          },
        }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          icon={<NoteAddIcon />}
          tooltipTitle='Create a new prompt'
          onClick={() => {
            setPrompt(null);
            setOpenCreateForm(true);
          }}
        />
      </SpeedDial>
    </>
  );
};

export default Prompts;
