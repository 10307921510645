import { React} from 'react';
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import FMListItem from './FMListItem';

const FMList = ({ error, files, type, openDeleteModal, openImageModal, selectedFiles, handleCheckboxChange}) => {
  const theme = useTheme();
  const selectedView = type === 'selectedFiles';

  if ((error || !files || files.length === 0) && !selectedView) {
    return (
      <>
        <Typography variant='body2' align='center' sx={{ my: 2 }}>
          {error || `No ${type} in the project yet.`}
        </Typography>

        <Typography
          variant='body2'
          align='center'
          color={theme.palette.text.secondary}
        >
          Upload corpus files by dragging and dropping them into the file upload
          panel.
        </Typography>
      </>
    );
  } else {
    return (
      <Container>
        <Grid container justifyContent='center' spacing={2}>
          <Grid item xs={12}>
            <TableContainer sx={{ pt: 2 }}>
              <Table size='small' aria-label='file table'>
                <TableHead>
                  <TableRow>
                  {!selectedView ? (<TableCell sx={{ fontWeight: 'bold' }}>#</TableCell>) : 
                  (<TableCell sx={{ fontWeight: 'bold' }}>Files</TableCell>)}
                    <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Size (Kb)</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Created</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                    {!selectedView && <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {files.map((file, index) => (
                    <FMListItem
                      key={file.id}
                      index={index + 1}
                      file={file}
                      openDeleteModal={openDeleteModal}
                      openImageModal={openImageModal}
                      handleCheckboxChange={handleCheckboxChange}
                      isSelected={selectedFiles?.includes(file)}
                      type={type}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    );
  }
};

export default FMList;
