import React from 'react';
import { Container, Grid, Typography } from '@mui/material';

const ContentBiblio = ({ biblio }) => {
  return (
    <Container maxWidth='md' sx={{ mt: 3, mb: 1 }}>
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='flex-start'
        justifyContent='left'
      >
        {biblio.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant='body2' fontSize={11} fontWeight='bold'>
                Bibliography:
              </Typography>
            </Grid>

            {biblio.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Typography variant='body2' fontSize={11}>
                  {index + 1}. {item.docName}
                  {/* (score: {item.avgSimilarity}) */}
                </Typography>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default ContentBiblio;
