
export const VerifyDrag = (section, draggedItemWithChildren, itemAbove, itemBelow, items, parentChildrenSize) => {
    let valid;

    switch (section) {
        case 'topic': {
            valid = (itemAbove.section === 'topic' && itemBelow.section === 'topic') ||
                (!draggedItemWithChildren.some(item => item.seq === itemAbove.seq) && (
                    (itemAbove.section === 'subtopic' && itemBelow.section === 'topic') ||
                    (itemAbove.section === 'body' && itemBelow.section === 'topic') ||
                    (itemAbove.section === 'definition' && itemBelow.section === 'topic') ||
                    (itemAbove.section === 'image' && itemBelow.section === 'topic')
                )
            )   
            break
        }
        case 'subtopic': {
            valid = (itemBelow === undefined && (
                (itemAbove.section === 'subtopic') || 
                (itemAbove.section === 'body') || 
                (itemAbove.section === 'definition') || 
                (itemAbove.section === 'image')
            )) ||
            (itemAbove.section === 'topic' && parentChildrenSize === 0) ||
            (itemAbove.section === 'subtopic' && itemBelow.section === 'subtopic') ||
            (!draggedItemWithChildren.some(item => item.seq === itemAbove.seq) && itemBelow !== undefined && (
                (itemAbove.section === 'topic' && itemBelow.section === 'subtopic') ||
                (itemAbove.section === 'subtopic' && itemBelow.section === 'topic') ||
                (itemAbove.section === 'body' && itemBelow.section === 'subtopic') ||
                (itemAbove.section === 'definition' && itemBelow.section === 'subtopic') ||
                (itemAbove.section === 'body' && itemBelow.section === 'topic') ||
                (itemAbove.section === 'definition' && itemBelow.section === 'topic') ||
                (itemAbove.section === 'image' && itemBelow.section === 'subtopic') ||
                (itemAbove.section === 'image' && itemBelow.section === 'topic')
            ))
            break
        }
        case 'image': {
            valid = (itemAbove !== undefined)
            break
        }
        default: {
            valid = (itemBelow === undefined && (
                ((itemAbove.section === 'subtopic') && parentChildrenSize === 0) ||
                (itemAbove.section === 'body') ||
                (itemAbove.section === 'definition') ||
                ((itemAbove.section === 'image') && (items.find(item => item.seq === itemAbove.parentId).section !== 'topic'))
            )) ||
            (itemBelow !== undefined && (
                (itemAbove.section === 'subtopic' && parentChildrenSize === 0) ||
                (itemAbove.section === 'subtopic' && itemBelow.section === 'body') ||
                (itemAbove.section === 'subtopic' && itemBelow.section === 'definition') ||
                (itemAbove.section === 'body' && itemBelow.section === 'topic') ||
                (itemAbove.section === 'body' && itemBelow.section === 'subtopic') ||
                (itemAbove.section === 'body' && itemBelow.section === 'body') ||
                (itemAbove.section === 'body' && itemBelow.section === 'definition') ||
                (itemAbove.section === 'definition' && itemBelow.section === 'topic') ||
                (itemAbove.section === 'definition' && itemBelow.section === 'subtopic') ||
                (itemAbove.section === 'definition' && itemBelow.section === 'body') ||
                (itemAbove.section === 'definition' && itemBelow.section === 'definition') ||
                (items.find(item => item.seq === itemAbove.parentId)?.section !== 'topic' && 
                    (itemAbove.section === 'image' && 
                        (   
                            itemBelow.section === 'topic' ||
                            itemBelow.section === 'subtopic' ||
                            itemBelow.section === 'body' ||
                            itemBelow.section === 'definition'
                        )
                    )
                )
            ))    
            break
        }
    }
    
    return valid;
}

export const dndResult = (section, itemsWoutDragged, draggedItemWithChildren, itemAbove, itemBelow, items, dropDirection, parentChildrenSize) => {

    let reorderedItems = [];

    switch (section) {
        case 'topic': {
            let destinationIndex = dropDirection > 0 ? itemsWoutDragged.findIndex(item => item.seq === itemAbove.seq) : itemsWoutDragged.findIndex(item => item.seq === itemBelow.seq);

            // drop downwards
            if (dropDirection > 0) {
                const childrenSize = parentChildrenSize;
                destinationIndex += childrenSize + 1; // add 1 to account for the dragged item
            }

            reorderedItems = [
                ...itemsWoutDragged.slice(0, destinationIndex),
                ...draggedItemWithChildren,
                ...itemsWoutDragged.slice(destinationIndex),
            ];

            break;

            // return reorderedItems;
        }
        
        case 'subtopic': {
            // set new parent id for dragged item
            const newParentId = () => {
                switch (itemAbove.section) {
                    case 'topic': return itemAbove.seq;
                    case 'subtopic': return itemAbove.parentId;
                    case 'image':
                        const parent = items.find(item => item.seq === itemAbove.parentId);
                        if (parent.section === 'topic') return parent.seq;
                        if (parent.section === 'subtopic') return parent.parentId;
                        return items.find(item => item.seq === parent.parentId).parentId;
                    default: return items[items.findIndex(item => item.seq === itemAbove.parentId)].parentId;
                }
            };

            draggedItemWithChildren[0].parentId = newParentId();

            let destinationIndex = dropDirection > 0 ? 
                itemsWoutDragged.findIndex(item => item.seq === itemAbove.seq) : itemsWoutDragged.findIndex(item => item.seq === itemBelow.seq);

            // drop downwards
            if (dropDirection > 0) {
                const childrenSize = parentChildrenSize;
                // if dropping subtopic right underneath another topic, no need to account for children size
                destinationIndex += itemAbove.section === 'topic' ? 1 : childrenSize + 1; // added 1 to include the last child in slice
            }

            reorderedItems = [
                ...itemsWoutDragged.slice(0, destinationIndex),
                ...draggedItemWithChildren,
                ...itemsWoutDragged.slice(destinationIndex),
            ];

            break;
            // return reorderedItems;
        }

        case 'image': {
            // set new parent id for dragged item
            let newParentId = itemAbove.section === 'image' ? itemAbove.parentId : itemAbove?.seq;
            draggedItemWithChildren[0].parentId = newParentId;
            
            let destinationIndex = dropDirection > 0 ? itemsWoutDragged.findIndex(item => item.seq === itemAbove.seq) : itemsWoutDragged.findIndex(item => item.seq === itemBelow.seq);
            
            // drop downwards
            if (dropDirection > 0) {
                destinationIndex += 1;
            }
            
            reorderedItems = [
                ...itemsWoutDragged.slice(0, destinationIndex),
                ...draggedItemWithChildren,
                ...itemsWoutDragged.slice(destinationIndex),
            ];
            
            break;
        }
        
        default: {
            const newParentId = () => {
                switch (itemAbove.section) {
                    case 'subtopic': return itemAbove.seq;
                    case 'image':
                        const parent = items.find(item => item.seq === itemAbove.parentId);
                        if (parent.section === 'subtopic') return parent.seq;
                        return items.find(item => item.seq === itemAbove.parentId).parentId;
                    default: return itemAbove.parentId;
                }
            };

            // set new parent id for dragged item
            draggedItemWithChildren[0].parentId = newParentId();

            let destinationIndex = dropDirection > 0 ? itemsWoutDragged.findIndex(item => item.seq === itemAbove.seq) : itemsWoutDragged.findIndex(item => item.seq === itemBelow.seq);

            // drop downwards
            if (dropDirection > 0) {
                const childrenSize = parentChildrenSize;
                // if dropping body right underneath another subtopic, no need to account for children size
                destinationIndex += itemAbove.section === 'subtopic' ? 1 : childrenSize + 1; // added 1 to include the last child in slice
            }

            reorderedItems = [
                ...itemsWoutDragged.slice(0, destinationIndex),
                ...draggedItemWithChildren,
                ...itemsWoutDragged.slice(destinationIndex),
            ];

            break;
        }
    }

    return reorderedItems;
}
