import { Box, IconButton, Tooltip } from '@mui/material';
import {
	ExpandMore as ExpandMoreIcon,
	ExpandLess as ExpandLessIcon,
	Delete as DeleteIcon,
} from '@mui/icons-material';

import { useState } from 'react';
import ConfirmModal from '../../../shared/components/ui/ConfirmModal';

const ListItemRight = ({ section, title, prompt, isExpanded, onToggle, hasChildren, deleteHandler }) => {

	const [deleteIsOpen, setDeleteIsOpen] = useState(false);

	const handleDelete = (confirm) => {
		if (confirm) {
			deleteHandler();
		}
		setDeleteIsOpen(false);
	}

	return (
		<>
		<Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(e) => e.stopPropagation()}>
			{hasChildren &&
				<IconButton 
					size='small' 
					onClick={ (e) => { e.stopPropagation(); onToggle(); }}
					sx={{
						transform: 'rotate(0deg)',
						transition: 'transform 0.25s ease-in-out',
						'&:hover': {
							transform: 'rotate(180deg)',
						}
					}}
				>
					{isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
				</IconButton>
			}

			<Tooltip title='Delete'>
				<IconButton className='delete-icon' size='small' color='error' sx={{ visibility: 'hidden' }} onClick={ (e) => { e.stopPropagation(); setDeleteIsOpen(true) }}>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		</Box>
		
		<ConfirmModal
			isOpen={deleteIsOpen}
			setIsOpen={setDeleteIsOpen}
			title={`Are you sure you want to delete this ${section}?`}
			infoObject={{
				Title: title,
				...(section !== 'image' && { Prompt: prompt })
			}}
			callback={handleDelete}
		/>
		</>

	);
};

export default ListItemRight;
