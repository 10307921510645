import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
	Modal,
	Box,
	Typography,
	Button,
	CircularProgress,
} from '@mui/material';

const ThumbnailModal = ({ isOpen, onClose, file, setSnackbar }) => {
	const [thumbnail, setThumbnail] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchImage = async () => {
			if (isOpen && file) {
				setLoading(true);
				setError(null);

				try {
					const response = await axios.get(
						`${process.env.REACT_APP_API_URL}/cloud/thumbnail?folder=${file.bucket}&filePath=${file.inputPath}`,
						{ responseType: 'blob' }
					);

					if (response.status === 200) {
						// create a URL for the thumbnail image blob
						const image = URL.createObjectURL(response.data);
						setThumbnail(image);
					} else {
						setError('Failed to fetch thumbnail');
						setSnackbar(true, 'Failed to fetch thumbnail');
					}
					setLoading(false);

				} catch (error) {
					console.error('Error generating thumbnail:', error);
					setError('Error fetching thumbnail');
					setLoading(false);
				}
			}
		};

		fetchImage();
	}, [isOpen, file, setSnackbar]);

	const handleClose = () => {
		onClose();
		// Revoke the object URL to free up memory
		if (thumbnail) {
			URL.revokeObjectURL(thumbnail);
			setThumbnail(null);
		}
	};

	return (
		<div>
			<Modal
				open={isOpen}
				onClose={handleClose}
				aria-labelledby='thumbnail-modal-title'
				aria-describedby='thumbnail-modal-description'
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography sx={{ mb: 1 }} variant='body1' textAlign='center'>
						{file.name}
					</Typography>
					{loading ? (
						<Box textAlign='center'>
							<CircularProgress color='secondary' />
						</Box>
					) : error ? (
						<Typography sx={{ mt: 2 }} align='center'>
							<span>{error}</span>
						</Typography>
					) : (
						thumbnail && (
							<img src={thumbnail} alt={file.name} style={{ width: '100%' }} />
						)
					)}
					<Box textAlign='right'>
						<Button onClick={onClose} sx={{ mt: 2 }} variant='contained'>
							Close
						</Button>
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

export default ThumbnailModal;
