import axios from 'axios';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';

// eslint-disable-next-line
import { Box, Button, Switch, FormControlLabel, useTheme, CircularProgress, Backdrop, Collapse } from '@mui/material';

// eslint-disable-next-line
import { ArrowRight, Add } from '@mui/icons-material';

import OutlineListItem from './list/OutlineListItem';
import { VerifyDrag, dndResult } from './list/dnd-logic';
import { DataContext } from '../../shared/context/data-context';
import ThumbnailModal from '../../shared/components/ui/ThumbnailModal';
import ImageGalleryModal from '../../shared/components/ui/ImageGalleryModal';

import { debounce, isEqual } from 'lodash';
import ProjectStepper from '../../shared/components/ui/ProjectStepper';

let uniqueID = 0;	// ensure each items get a unique seq

const OutlineList = ({ setSnackbar, newVersion, setNewVersion }) => {
    const dataContext = useContext(DataContext);
    const { outlineId } = useParams('outlineId');                       // outlineId from URL
    const theme = useTheme();                                           // theme from context

    const [project, setProject] = useState();                           // project from context
    const [outlineName, setOutlineName] = useState();                   // outline name

    const [items, setItems] = useState([]);								// array of all items
    const [expandedItems, setExpandedItems] = useState([]);				// array of items that are in expanded state
    const [autoSave, setAutoSave] = useState(true);						// auto save state
    const [loading, setLoading] = useState(true);						// loading state while components get loaded

    const [selectedImageFile, setSelectedImageFile] = useState(null);			// selected image for viewing
    const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);        // image gallery modal for image selection
    const [isThumbnailModalOpen, setIsThumbnailModalOpen] = useState(false);    // preview image modal
    const [pendingImageItem, setPendingImageItem] = useState(null);				// pending image item to be added
    const [cachedGalleryImages, setCachedGalleryImages] = useState(null);       // cached images for the gallery

    const previousItems = useRef(items);    // to keep track of previous items for updates

    // Save outline to backend
    const saveOutline = useCallback(async (updatedItems) => {
        if (!updatedItems || (updatedItems && updatedItems.length === 0 && previousItems.current.length === 0)) {
            setSnackbar(true, 'Nothing to save. Use the button above to start creating an outline.');
            return;
        }

        if (isEqual(updatedItems, previousItems.current)) {
            setSnackbar(true, 'No changes to save.');
            return;
        }

        let data;
        try {
            if (outlineId) {
                const response = await axios.patch(`${process.env.REACT_APP_API_URL}/outlines/${outlineId}`, { outline: updatedItems }, { headers: { 'Content-Type': 'application/json' } });
                data = response.data;
                setItems(() => {
                    const newItems = backwardsCompatibility(data.outlines.outline);
                    previousItems.current = newItems;
                    return newItems;
                });
                setSnackbar(true, 'Outline saved');
            }
        } catch (error) {
            setSnackbar(true, error.response?.data.message || 'Unable to save outline');
        }
    }, [outlineId, setSnackbar]);

    // autosave feature
    useEffect(() => {
        if (!autoSave) return;

        if (isEqual(items, previousItems.current)) {
            return;
        }

        const debouncedSave = debounce((items) => {
            saveOutline(items);
        }, 1500);

        debouncedSave(items);

    }, [items, autoSave, saveOutline]);

    // Load outline from backend and project context
    useEffect(() => {
        const project = dataContext.getProject();
        setProject(project);

        const fetchOutline = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/outlines/outline/${outlineId}`);
                setOutlineName(data.outline.name);

                if (data.outline.outline.length > 0) {
                    setItems(() => {
                        const newItems = backwardsCompatibility(data.outline.outline);
                        setExpandedItems(newItems.map(item => item.seq));
                        previousItems.current = newItems;
                        uniqueID = data.outline.outline.length > 0 ? Math.max(...data.outline.outline.map(item => item.seq)) : 0;
                        return newItems;
                    });
                    // setSnackbar(true, 'Outline loaded successfully');
                } else {
                    setSnackbar(true, 'No items to load. Use the button above to start creating an outline.');
                }
            } catch (err) {
                setSnackbar(true, err.response?.data.message || 'Error loading data');
                setItems([]);
                setOutlineName();
            } finally {
                setLoading(false);
                // expand all items on first load
                setExpandedItems(() => { return items.map((item) => item.seq) });
            }
        };

        if (outlineId) {
            fetchOutline();
        }

        // cleanup cached images on unmount
        return () => {
            if (cachedGalleryImages) {
                console.log('Revoking object URLs');
                cachedGalleryImages.forEach(image => URL.revokeObjectURL(image.src));
            }
        };

        // eslint-disable-next-line
    }, [outlineId, dataContext]);

    // Open Image Gallery for selecting an image
    const handleOpenImageGallery = () => {
        setIsImageGalleryOpen(true);
    };

    // Set selected image for new item and close the gallery modal
    const handleSelectImage = (imageFile) => {
        if (pendingImageItem) {
            const newItem = {
                ...pendingImageItem,
                title: imageFile.name,
                prompt: { bucket: imageFile.bucket, inputPath: imageFile.inputPath, name: imageFile.name },
            };
            const insertIndex = findInsertIndex(newItem, newItem.parentId);
            const itemsAppended = [...items];
            itemsAppended.splice(insertIndex, 0, newItem);
            setItems(itemsAppended);
            setPendingImageItem(null);
        }
        setIsImageGalleryOpen(false);
    };

    // Open Thumbnail Modal to view the selected image
    const handleOpenThumbnailModal = (item) => {
        setSelectedImageFile(item.prompt);
        setIsThumbnailModalOpen(true);
    };

    // callback function to maintain updated items from ListItemCenter child
    const handleItemUpdate = (seq, updates) => {
        const item = items.find((item) => item.seq === seq);

        // only update if there are changes
        if (item.title === updates.title && item.prompt === updates.prompt) {
            return;
        }

        // setItems(prevItems => prevItems.map(item => item.seq === seq ? { ...item, ...updates } : item));
        setItems((prevItems) => {
            return prevItems.map((item) => {
                return item.seq === seq ? { ...item, ...updates } : item;
            });
        });
    };

    // function to ensure correct hierarchy for backwards compatibility (assumes that prevItems is in correct flattened order)
    const backwardsCompatibility = (prevItems) => {
        const outline = [];
        const stack = [];

        if (prevItems.length === 0) {
            return prevItems;
        }

        // prevItems already has parentId field => no need for backwards compatibility check
        if (prevItems[0].hasOwnProperty('parentId')) {
            return prevItems;
        }


        prevItems.forEach((item) => {
            // drop leftover subtopics from the previous topic
            if (item.section === 'topic') {
                stack.length = 0;
                item.parentId = null;
            }

            // pop until we find a topic
            else if (item.section === 'subtopic') {
                while (stack.length && stack[stack.length - 1].section !== 'topic') {
                    stack.pop();
                }
                if (stack.length) {
                    item.parentId = stack[stack.length - 1].seq;
                }
            }

            // attach the image to the first non-image item above
            else if (item.section === 'image') {
                if (stack.length) {

                    if (stack[stack.length - 1].section === 'image') {
                        item.parentId = stack[stack.length - 1].parentId;
                    } else {
                        item.parentId = stack[stack.length - 1].seq;
                    }
                }
            }

            // handle body or definition
            else {
                const top = stack[stack.length - 1];
                if (top?.section === 'definition' || top?.section === 'body') {
                    item.parentId = top.parentId;
                }
                else if (top?.section === 'image') {
                    const aboveImage = stack[stack.length - 2];
                    if (aboveImage?.section === 'definition' || aboveImage?.section === 'body') {
                        item.parentId = aboveImage.parentId;
                    }

                    else if (aboveImage.section === 'image') {
                        const parent = stack.find((item) => item.seq === aboveImage.parentId)

                        if (parent.section === 'definition' || parent.section === 'body') {
                            item.parentId = parent.parentId;
                        } else {
                            item.parentId = aboveImage.parentId;
                        }
                    }
                    else {
                        item.parentId = aboveImage.seq;
                    }
                }
                else {
                    item.parentId = top?.seq ?? null;
                }
            }

            stack.push(item);
            outline.push(item);
        });

        return outline;
    };

    // Helper function to get all children ids recursively
    const getAllChildrenIds = (seq) => {
        let childrenIds = items.filter((item) => item.parentId === seq).map((item) => item.seq);
        childrenIds.forEach((childId) => {
            childrenIds = childrenIds.concat(getAllChildrenIds(childId));
        });
        return childrenIds;
    };

    // helper function to handle expanding and collapsing items
    const handleToggle = (seq) => {
        setExpandedItems((prevExpandedItems) => {
            // If the item is currently expanded, collapse it and its children
            if (prevExpandedItems.includes(seq)) {
                const allChildrenIds = getAllChildrenIds(seq);
                return prevExpandedItems.filter((itemId) => itemId !== seq && !allChildrenIds.includes(itemId));
            }

            // Expand the item
            else {
                return [...prevExpandedItems, seq];
            }
        });
    };

    // delete function
    const onDelete = (seq) => {
        const allIdsToDelete = [seq, ...getAllChildrenIds(seq)];
        setItems((prevItems) => prevItems.filter((item) => !allIdsToDelete.includes(item.seq)));
        setExpandedItems((prevExpandedItems) => prevExpandedItems.filter((itemId) => !allIdsToDelete.includes(itemId)));
    };

    // helper function to find insertion location based on parent and type
    const findInsertIndex = (newItem, parentId) => {
        const parentItem = items.find((item) => item.seq === parentId);

        // default: right after parent
        let insertIndex = items.findIndex((item) => item.seq === parentId) + 1;
        const childrenCount = getAllChildrenIds(parentId).length;

        // count the number of images that belong to the parent and insert after them
        let imageCount = items.filter((item) => item.parentId === parentId && item.section === 'image').length;

        switch (newItem.section) {
            case 'definition':
                insertIndex += imageCount;
                newItem.parentId = parentItem.parentId;
                break;
            case 'body':
                insertIndex += imageCount;
                if (parentItem.section === 'body' || parentItem.section === 'definition') {
                    newItem.parentId = parentItem.parentId;
                }
                break;
            case 'subtopic':
                if (parentItem.section === 'subtopic') {
                    insertIndex += childrenCount;
                    newItem.parentId = parentItem.parentId;
                } else {
                    insertIndex += imageCount;
                }
                break;
            case 'topic':
                insertIndex += childrenCount;
                break;
            default:
                break;
        }

        // expand the parent if it's not already expanded and the added item is not a topic
        if (newItem.section !== 'topic' && !expandedItems.includes(parentId) && !(newItem.section === 'subtopic' && parentItem.section === 'subtopic')) {
            setExpandedItems((prevExpandedItems) => {
                return [...prevExpandedItems, parentId];
            });
        }

        return insertIndex;
    };

    // callback function to add new item
    const onAddNewItem = (section, parentId) => {
        const newItem = {
            seq: ++uniqueID,
            section,
            title: `Click this text to edit ${section.charAt(0).toUpperCase() + section.slice(1)} title`,
            prompt: `Click this text to edit ${section.charAt(0).toUpperCase() + section.slice(1)} prompt`,
            parentId: section === 'topic' ? null : parentId,    // add it as a child of the selected item or null if topic
        };

        // Find the appropriate index to insert the new item based on type and parent
        const insertIndex = findInsertIndex(newItem, parentId);

        /// Insert the new item at the correct position
        const itemsAppended = [...items];
        itemsAppended.splice(insertIndex, 0, newItem); // Insert at the determined index

        setItems(itemsAppended); // Update state with the new item list    
    };

    // callback function to add an image item
    const onAddNewImage = (parentId) => {
        handleOpenImageGallery();

        // Store parent info for when image is selected
        const imageItemInfo = {
            parentId: parentId,
            seq: ++uniqueID,
            section: 'image',
        };

        setPendingImageItem(imageItemInfo);
    };

    // tracking visible items by mapping underlying items array to visible indices
    const visibleItems = [];
    const getVisibleItems = () => {
        if (items != null) {
            items.forEach((item) => {
                // (a) topic items are always visible and (b) expanded items must be visible
                if (item.parentId === null || expandedItems.includes(item.parentId)) {
                    visibleItems.push(item);
                }
            });
        }
    };
    getVisibleItems();


    const handleTopicDrag = (draggedItemWithChildren, itemsWoutDragged, droppedIndex, dropDirection) => {

        // obtain the items above and below the dragged item's destination
        const itemAbove = dropDirection > 0 ? visibleItems[droppedIndex] : visibleItems[droppedIndex - 1];
        const itemBelow = dropDirection > 0 ? visibleItems[droppedIndex + 1] : visibleItems[droppedIndex];

        // topic dragged to top of the list
        if (droppedIndex === 0) {
            setItems([...draggedItemWithChildren, ...itemsWoutDragged]);
            return [...draggedItemWithChildren, ...itemsWoutDragged];
        }

        // topic dragged to bottom of the list
        else if (droppedIndex === visibleItems.length - 1) {
            setItems([...itemsWoutDragged, ...draggedItemWithChildren]);
            return [...itemsWoutDragged, ...draggedItemWithChildren];
        }

        // topic dragged to middle of the list
        else {
            const isValidDrop = VerifyDrag(
                'topic',
                draggedItemWithChildren,
                itemAbove,
                itemBelow,
                items,
                getAllChildrenIds(itemAbove.seq).length
            );

            if (isValidDrop) {
                const reorderedItems = dndResult(
                    'topic',
                    itemsWoutDragged,
                    draggedItemWithChildren,
                    itemAbove,
                    itemBelow,
                    items,
                    dropDirection,
                    getAllChildrenIds(itemAbove.seq).length
                );

                setItems(reorderedItems);
                return;
            }

            setSnackbar(true, 'Invalid drop');
        }
    };

    const handleSubtopicDrag = (draggedItemWithChildren, itemsWoutDragged, droppedIndex, dropDirection) => {
        // obtain the items above and below the dragged item's destination
        const itemAbove = dropDirection > 0 ? visibleItems[droppedIndex] : visibleItems[droppedIndex - 1];
        const itemBelow = dropDirection > 0 ? visibleItems[droppedIndex + 1] : visibleItems[droppedIndex];

        const isValidDrop = VerifyDrag(
            'subtopic',
            draggedItemWithChildren,
            itemAbove,
            itemBelow,
            items,
            getAllChildrenIds(itemAbove.seq).length
        );

        if (isValidDrop) {
            const reorderedItems = dndResult(
                'subtopic',
                itemsWoutDragged,
                draggedItemWithChildren,
                itemAbove,
                itemBelow,
                items,
                dropDirection,
                getAllChildrenIds(itemAbove.seq).length
            );

            setItems(reorderedItems);
            return;
        }

        setSnackbar(true, 'Invalid drop');
        return null;
    }

    const handleBodyDefinitionDrag = (draggedItemWithChildren, itemsWoutDragged, droppedIndex, dropDirection) => {

        // obtain the items above and below the dragged item's destination
        const itemAbove = dropDirection > 0 ? visibleItems[droppedIndex] : visibleItems[droppedIndex - 1];
        const itemBelow = dropDirection > 0 ? visibleItems[droppedIndex + 1] : visibleItems[droppedIndex];

        const isValidDrop = VerifyDrag(
            'body/definition',
            draggedItemWithChildren,
            itemAbove,
            itemBelow,
            items,
            getAllChildrenIds(itemAbove.seq).length
        );


        if (isValidDrop) {
            const reorderedItems = dndResult(
                'body/definition',
                itemsWoutDragged,
                draggedItemWithChildren,
                itemAbove,
                itemBelow,
                items,
                dropDirection,
                getAllChildrenIds(itemAbove.seq).length
            );

            setItems(reorderedItems);
            return;
        }

        setSnackbar(true, 'Invalid drop');
    }

    const handleImageDrag = (draggedItemWithChildren, itemsWoutDragged, droppedIndex, dropDirection) => {
        const itemAbove = dropDirection > 0 ? visibleItems[droppedIndex] : visibleItems[droppedIndex - 1];
        const itemBelow = dropDirection > 0 ? visibleItems[droppedIndex + 1] : visibleItems[droppedIndex];
        const isValidDrop = VerifyDrag(
            'image',
            draggedItemWithChildren,
            itemAbove,
            itemBelow,
            items,
            0
        );
        if (isValidDrop) {
            const reorderedItems = dndResult(
                'image',
                itemsWoutDragged,
                draggedItemWithChildren,
                itemAbove,
                itemBelow,
                items,
                dropDirection,
                getAllChildrenIds(itemAbove.seq).length
            );
            setItems(reorderedItems);
            return;
        }
        setSnackbar(true, 'Invalid drop');
    }

    // Handle item dragging
    const onDragEnd = (result) => {
        const { source, destination } = result;

        // do nothing if dropped outside dropzone or no change in position
        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) return;


        // get the dragged item itself
        const draggedItemId = parseInt(result.draggableId, 10);
        const draggedItem = items.find(item => item.seq === draggedItemId);

        // combined the dragged item and its children into a single array
        const draggedItemWithChildren = [draggedItem, ...items.filter(item => (getAllChildrenIds(draggedItem.seq).includes(item.seq)))];

        // remove the dragged item and its children from items array
        const itemsWoutDragged = items.filter(item => !draggedItemWithChildren.some(dragged => dragged.seq === item.seq));

        switch (draggedItem.section) {
            case 'topic':
                handleTopicDrag(draggedItemWithChildren, itemsWoutDragged, destination.index, destination.index - source.index);
                break;
            case 'subtopic':
                handleSubtopicDrag(draggedItemWithChildren, itemsWoutDragged, destination.index, destination.index - source.index);
                break;
            case 'image':
                handleImageDrag(draggedItemWithChildren, itemsWoutDragged, destination.index, destination.index - source.index);
                break;
            default:
                handleBodyDefinitionDrag(draggedItemWithChildren, itemsWoutDragged, destination.index, destination.index - source.index);
                break;
        }
    };

    const handleVersionChange = async (e) => {
        setLoading(true);
        try {
            // Check if items match previousItems
            if (!isEqual(items, previousItems.current)) {
                await saveOutline(items);
                await new Promise(resolve => setTimeout(resolve, 300));
            }
            setNewVersion(false);
        } catch (error) {
            setSnackbar(true, 'Failed to save before switching views');
            // Reset switch state on error
            e.target.checked = !e.target.checked;
        } finally {
            setLoading(false);
        }
    };

    // Render items based on their expanded state
    const renderItems = () => {
        let globalIndex = 0;

        if (items == null) {
            setSnackbar(true, 'No items to render');
            return null;
        }

        // if there are no items, display a button to add a new topic
        if (items.length === 0) {
            return (
                <Box sx={{ textAlign: 'center', padding: '20px' }}>
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<Add />}
                        onClick={() => onAddNewItem('topic', null)}
                    >
                        Add Topic
                    </Button>
                </Box>
            );
        }

        // render items
        return items.map((item) => {
            const isVisible = item.parentId === null || expandedItems.includes(item.parentId);
            return (
                <Collapse
                    key={item.seq}
                    in={isVisible}
                    timeout={400}
                    easing={{
                        enter: 'cubic-bezier(0.4, 0, 0.2, 1)',
                        exit: 'cubic-bezier(0.4, 0, 0.2, 1)'
                    }}
                    unmountOnExit
                >
                    <OutlineListItem
                        item={item}
                        index={isVisible ? globalIndex++ : 0}
                        config={{
                            expandedItems,
                            hasChildren: items.some((i) => i.parentId === item.seq)
                        }}
                        handlers={{
                            toggleExpand: handleToggle,
                            deleteHandler: () => onDelete(item.seq),
                            addItemHandler: onAddNewItem,
                            addImageHandler: onAddNewImage,
                            previewImageHandler: handleOpenThumbnailModal,
                            handleItemUpdate: handleItemUpdate
                        }}
                    />
                </Collapse>
            );
        });
    }

    return (
        <>
            <ProjectStepper
                project={project}
                activeStep={1}
                crumb={`Outline: ${outlineName}`}
            />

            <Box sx={{
                padding: '30px',
                width: '80%',
                height: '100%',
                overflow: 'auto',
                margin: '0 auto',
                maxWidth: '1200px'
            }}>

                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color='secondary' />
                </Backdrop>


                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Left side - Auto Save */}
                    <Box>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={autoSave}
                                    onChange={(e) => setAutoSave(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Auto Save"
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={newVersion}
                                    onChange={handleVersionChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Nested View"
                        />

                        <Button
                            disabled={autoSave}
                            sx={{ ml: 1, mr: 3, width: '80px' }}
                            variant='contained'
                            onClick={() => saveOutline(items)}
                        >
                            Save
                        </Button>
                    </Box>

                    {/* Middle - Outline Title */}
                    {/* <Box style={{ textAlign: 'center' }}>
                        <h1 style={{ fontFamily: theme.typography.h1 }}>{outlineName}</h1>
                    </Box> */}

                    {/* Right side - Expand/Collapse */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button onClick={() => setExpandedItems(items.map((item) => item.seq))}>
                                Expand All
                            </Button>
                            <ArrowRight
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: 'large',
                                    transform: 'rotate(90deg)'
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button onClick={() => setExpandedItems([])}>
                                Collapse All
                            </Button>
                            <ArrowRight
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: 'large',
                                    transform: 'rotate(-90deg)'
                                }}
                            />
                        </Box>
                    </Box>
                </Box>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="outline">
                        {(provided) => (
                            <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                }}
                            >
                                {renderItems()}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>

                {/* Image Gallery Modal for selecting an image */}
                {project && (
                    <ImageGalleryModal
                        isOpen={isImageGalleryOpen}
                        onClose={() => setIsImageGalleryOpen(false)}
                        onSelectImage={handleSelectImage}
                        projectId={project._id}
                        setSnackbar={setSnackbar}
                        cachedImages={cachedGalleryImages}
                        setCachedImages={setCachedGalleryImages}
                    />
                )}

                {/* Thumbnail Modal for viewing and managing the selected image */}
                {selectedImageFile && (
                    <ThumbnailModal
                        isOpen={isThumbnailModalOpen}
                        onClose={() => setIsThumbnailModalOpen(false)}
                        file={selectedImageFile}
                        setSnackbar={setSnackbar}
                    />
                )}
            </Box>
        </>
    );
};

export default OutlineList;