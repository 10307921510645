import { useCallback, useState } from 'react';
import axios from 'axios';

export const useContextData = () => {
  const [project, setProject] = useState({});

  const getProject = useCallback(() => {
    return project;
  }, [project]);

  const storeProject = useCallback((project) => {
    setProject(project || {});
  }, []);

  const refreshProject = useCallback(async (projectId, isAssignment = false) => {
    try {
      if (projectId) {
        const endpoint = isAssignment
          ? `${process.env.REACT_APP_API_URL}/projects/a/${projectId}`
          : `${process.env.REACT_APP_API_URL}/projects/p/${projectId}`;
        const { data } = await axios.get(endpoint);
        if (data.project) {
          setProject(data.project);
        } else if (data.assignment) {
          setProject(data.assignment);
        }
      }
    } catch (error) {
      console.log(error.response?.data.message || 'Unable to refresh project.');
    }
  }, []);

  // const [data, setData] = useState({});
  // const addData = (key, value) => {
  //   setData({ ...data, key: value });
  // };
  // const removeData = (key) => {
  //   setData((current) => {
  //     const { key, ...rest } = current;
  //     return rest;
  //   });
  // };
  // const getData = (key) => {
  //   if (key) {
  //     return data[key];
  //   }
  //   return data;
  // };

  return { getProject, storeProject, refreshProject };
};
