import React from 'react';
import { Grid, Typography } from '@mui/material';
import IntroCard from './components/IntroCard';
import InfoIcon from '@mui/icons-material/Info';
import IconTemplate from './components/IconTemplate';

const About = () => {
	return (
		<IconTemplate title='About Us' image={InfoIcon}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography sx={{ lineHeight: '24px', py: 2 }} variant='smallerFont'>
						When new foundational technologies such as artificial intelligence
						emerge have the opportunity for a new approach to an old way of
						thinking.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography sx={{ lineHeight: '24px', py: 2 }} variant='smallerFont'>
						As practicioners, we know what we want to teach. However, in some fields,
						the appropriate text doesn't exist.  Even when we have the right text, we
						battle with academic integrity issues with question banks or we struggle
						writing exam questions integrating business cases with material from our text.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography sx={{ lineHeight: '24px', py: 2 }} variant='smallerFont'>
						Perhaps more importantly, we want more engagement.  We strive to make the
						material more relateable so our students see its importance.  Even if we
						can achive all these goals, not only will we want to rapidly change to
						make it better, but we will want to adapt quickly to student needs.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<IntroCard src='/static/images/john.jpg' alt='john'>
						With over two decades of academic expertise and technology leadership, 
						John Burr brings a wealth of teaching experience to our team. He previously 
						led an IT organization at Purdue University’s Discovery Park, specializing in 
						research computing. Now, as CEO, his goal is to leverage AI to provide a more 
						custom student experience.  John was also recently voted "most likely to bring 
						snacks for the group".
					</IntroCard>
				</Grid>
				<Grid item xs={12}>
					<IntroCard src='/static/images/robots.png' alt='shrini'>
						Shrini Jandhyala contributes over 20 years of enterprise-class
						software development and information security knowledge.
					</IntroCard>
				</Grid>
				<Grid item xs={12}>
					<IntroCard src='/static/images/sam.jpg' alt='sam'>
						Samuel is a recent Purdue University graduate with a degree in Computer and Data Science, specializing in 
						web development and crafting seamless user-friendly interfaces. Passionate about intuitive, high-performance 
						web solutions, he prioritizes usability and accessibility. At CorpusKey, he is dedicated to delivering smooth, 
						engaging online interactions that enhance user satisfaction and drive innovation.
					</IntroCard>
				</Grid>
				<Grid item xs={12}>
					<IntroCard src='/static/images/robert.jpg' alt='robert'>
						Robert is a Purdue University Computer Science alumnus and full-stack developer passionate about creating
						innovative web solutions. With expertise in both front-end and back-end developement, he thrives on
						transforming complex ideas into seamless digital experiences. At CorpusKey, he is dedicated to building user-focused
						technology that drives meangingful impact and growth.
					</IntroCard>
				</Grid>
				<Grid item xs={12}>
					<IntroCard src='/static/images/anant.png' alt='anant'>
						Anant is a senior at Purdue University pursuing a double major in Computer Science and Data Science, with a strong
						focus on backend development and multi-agent systems. At CorpusKey, he is excited to leverage large language models
						and machine learning expertise to advance educational technology, creating intelligent solutions that elevate user 
						experience and drive innovation.
					</IntroCard>
				</Grid>
				<Grid item xs={12}>
					<Typography sx={{ lineHeight: '24px', py: 4 }} variant='smallerFont'>
						Our founding team excels at striking a balance between Agile
						Development and Robustness, enabling us to build, adapt, and fortify
						solutions swiftly. We understand that the landscape of educational
						solutions evolves rapidly. Therefore, we continuously enhance our
						portfolio, develop novel AI models and agents, and refine underlying
						processes to cater to the ever-changing demands of the education
						market.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography
						variant='h5'
						align='center'
						sx={{ lineHeight: '24px', py: 2 }}
					>
						Our Vision
					</Typography>
					<Typography sx={{ lineHeight: '24px' }} variant='smallerFont'>
						While current AI capabilities have limitations, we envision them
						changing rapidly to achieve our goals. Despite some rough edges in
						existing tools, they already deliver significant value to various
						segments of the market. Natural language processing and image
						handling are advancing, enabling us to offer more precise
						assessments and recommendations; far beyond the rudimentary
						principles followed by others.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography sx={{ lineHeight: '24px', py: 2 }} variant='smallerFont'>
						Our primary objective is to provide you with the tools necessary to
						focus on high-value tasks. We believe educators should spend their
						time innovating in the classroom rather than grading essays. We are
						committed to proper source attribution, designed as a complementary
						resource where it&#39;s needed. Our tools empower instructors with
						semi-finished PowerPoint decks, the ability to incorporate current
						events into course materials, and improved assessments, all without
						the burden of extensive grading.
					</Typography>
				</Grid>
				<Grid item xs={12} sx={{ pb: 4 }}>
					<Typography sx={{ lineHeight: '24px', pb: 2 }} variant='smallerFont'>
						At CorpusKey, we are dedicated to shaping the future of education
						through AI-driven solutions. We know what it takes to help you
						succeed.
					</Typography>
				</Grid>
			</Grid>
		</IconTemplate>
	);
};

export default About;
