import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';

const ContentBody = ({ final, bullets, subtitle, definition, section, prompt }) => {

  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      if (section === 'image' && prompt) {
        setLoading(true);
        try {
          const bucket = prompt.split('/')[0];
          const filePath = prompt.split('/').slice(1).join('/');

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/cloud/thumbnail`,
            {
              params: {
                width: process.env.REACT_APP_THUMBNAIL_WIDTH,
                folder: bucket,
                filePath: filePath
              },
              responseType: 'blob'
            }
          );

          const blob = new Blob([response.data], { type: 'image/jpeg' });
          const url = URL.createObjectURL(blob);
          setImageUrl(url);
        } catch (error) {
          console.error('Error generating thumbnail:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchImage();

    // cleanup
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
        setImageUrl(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, prompt]);

  if (section === 'image') {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '200px',
          width: '100%',
          position: 'relative'
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          imageUrl && (
            <img
              src={imageUrl}
              alt="Content"
              style={{
                maxWidth: '100%',
                maxHeight: '500px',
                objectFit: 'contain'
              }}
            />
          )
        )}
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 2, mb: 1 }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {subtitle && (
          <Grid item xs={12}>
            <Typography variant="body2" fontWeight="bold">
              {subtitle}
            </Typography>
          </Grid>
        )}

        {final && (
          <Grid item xs={12}>
            <Typography variant="body2">{final}</Typography>
          </Grid>
        )}

        {definition && (
          <Grid item xs={12}>
            <Typography variant="body2" fontStyle="italic">
              {definition}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default ContentBody;
