import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Typography,
  useTheme,
} from '@mui/material';

const PromptNew = ({
  handleNewPromptCreation,
  llmOptions = [],
  fontVariant = 'body2',
}) => {
  const validationSchema = Yup.object({
    consumer: Yup.string().required('Consumer is required'),
    tag: Yup.string().required('Tag is required'),
    systemRole: Yup.string().required('System role is required'),
    userRole: Yup.string().required('User role is required'),
    llm: Yup.string().nullable().required('Please select an LLM option'),
    model: Yup.string(),
    temperature: Yup.string()
      .nullable()
      .transform((value) => (value === '' ? null : value))
      .when('$exists', {
        is: (value) => value != null && value !== '',
        then: (schema) =>
          schema
            .required('Required')
            .test(
              'is-number',
              'Must be a number',
              (value) => value === null || !isNaN(Number(value))
            ),
      }),
    penalty: Yup.string()
      .nullable()
      .transform((value) => (value === '' ? null : value))
      .when('$exists', {
        is: (value) => value != null && value !== '',
        then: (schema) =>
          schema
            .required('Required')
            .test(
              'is-number',
              'Must be a number',
              (value) => value === null || !isNaN(Number(value))
            ),
      }),
    maxTokens: Yup.string()
      .nullable()
      .transform((value) => (value === '' ? null : value))
      .when('$exists', {
        is: (value) => value != null && value !== '',
        then: (schema) =>
          schema
            .required('Required')
            .test(
              'is-number',
              'Must be a number',
              (value) => value === null || !isNaN(Number(value))
            ),
      }),
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      consumer: '',
      tag: '',
      systemRole: '',
      userRole: '',
      llm: '',
      model: '',
      temperature: '',
      penalty: '',
      maxTokens: '',
    },
  });

  const theme = useTheme();
  const [modelOptions, setModelOptions] = useState([]);
  const typographyObject = theme.typography[fontVariant];
  const fontSize = typographyObject?.fontSize || '0.979rem';

  const selectedLLM = watch('llm');

  useEffect(() => {
    setModelOptions(llmOptions[selectedLLM]);
    setValue('model', '');
  }, [selectedLLM, setValue, llmOptions]);

  const onClear = () => {
    reset({
      consumer: '',
      tag: '',
      systemRole: '',
      userRole: '',
      llm: '',
      model: '',
      temperature: '',
      penalty: '',
      maxTokens: '',
    });
  };

  const onSubmit = (formData) => {
    handleNewPromptCreation(formData, onClear);
  };

  return (
    <Box sx={{ m: 2 }}>
      <Typography variant='h6' gutterBottom>
        Create prompt
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='consumer'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size='small'
              fullWidth
              label='Consumer*'
              error={!!errors.consumer}
              helperText={errors.consumer?.message}
              sx={{
                my: 1,
                '& .MuiInputBase-input': {
                  fontSize,
                },
              }}
            />
          )}
        />
        <Controller
          name='tag'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size='small'
              fullWidth
              label='Tag*'
              error={!!errors.tag}
              helperText={errors.tag?.message}
              sx={{
                my: 1,
                '& .MuiInputBase-input': {
                  fontSize,
                },
              }}
            />
          )}
        />
        <Controller
          name='systemRole'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size='small'
              fullWidth
              label='System Role*'
              error={!!errors.systemRole}
              helperText={errors.systemRole?.message}
              multiline
              minRows={3}
              sx={{
                my: 1,
                '& .MuiInputBase-input': {
                  fontSize,
                },
              }}
            />
          )}
        />
        <Controller
          name='userRole'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size='small'
              fullWidth
              label='User Role*'
              error={!!errors.userRole}
              helperText={errors.userRole?.message}
              multiline
              minRows={3}
              sx={{
                my: 1,
                '& .MuiInputBase-input': {
                  fontSize,
                },
              }}
            />
          )}
        />
        <Controller
          name='llm'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              size='small'
              fullWidth
              label='LLM*'
              error={!!errors.llm}
              helperText={errors.llm?.message}
              sx={{
                my: 1,
                '& .MuiInputBase-input': {
                  fontSize,
                },
              }}
            >
              {Object.keys(llmOptions).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name='model'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              value={field.value}
              size='small'
              fullWidth
              label='Model'
              error={!!errors.model}
              helperText={errors.model?.message}
              disabled={!selectedLLM}
              sx={{
                my: 1,
                '& .MuiInputBase-input': {
                  fontSize,
                },
              }}
            >
              {modelOptions ? (
                modelOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key='' value=''></MenuItem>
              )}
            </TextField>
          )}
        />
        <Controller
          name='temperature'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size='small'
              fullWidth
              label='Temperature'
              type='number'
              error={!!errors.temperature}
              helperText={errors.temperature?.message}
              sx={{
                my: 1,
                '& .MuiInputBase-input': {
                  fontSize,
                },
              }}
            />
          )}
        />
        <Controller
          name='penalty'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size='small'
              fullWidth
              label='Penalty'
              type='number'
              error={!!errors.penalty}
              helperText={errors.penalty?.message}
              sx={{
                my: 1,
                '& .MuiInputBase-input': {
                  fontSize,
                },
              }}
            />
          )}
        />
        <Controller
          name='maxTokens'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size='small'
              fullWidth
              label='Max Tokens'
              type='number'
              error={!!errors.maxTokens}
              helperText={errors.maxTokens?.message}
              sx={{
                my: 1,
                '& .MuiInputBase-input': {
                  fontSize,
                },
              }}
            />
          )}
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant='contained'
            color='secondary'
            onClick={onClear}
            sx={{ my: 2 }}
          >
            Clear
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            sx={{ ml: 2, my: 2 }}
          >
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default PromptNew;
