import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import LoadingButton from '../../../../shared/components/ui/LoadingButton';

const updateSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  creator: Yup.string().required('Required'),
});

const ProjectCreateModal = ({
  isOpen,
  setIsOpen,
  userId,
  handleSnackbarUpdate,
  handleProjectCreate,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { name: '', description: '' },
    resolver: yupResolver(updateSchema),
  });

  const handleCreate = useCallback(
    async (formData) => {
      setIsLoading(true);

      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/projects/${formData.creator}`,
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        handleProjectCreate(data.project);
        handleSnackbarUpdate('Project created!');
      } catch (error) {
        handleSnackbarUpdate(
          error.response?.data.message ||
            'Unable to create project. Please try again.'
        );
      }
      setIsLoading(false);
      setIsOpen(false);
    },
    [handleProjectCreate, handleSnackbarUpdate, setIsOpen]
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        minWidth={theme.breakpoints.values.sm}
        justifyContent='center'
        alignItems='center'
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: `1px solid ${theme.palette.primary.dark}`,
          borderRadius: '8px',
          boxShadow: 12,
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card elevation={12} sx={{ width: '100%' }}>
          <CardHeader
            title='Create New Project'
            action={
              <Tooltip title='Close'>
                <IconButton
                  aria-label='close'
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <CancelRoundedIcon />
                </IconButton>
              </Tooltip>
            }
            sx={{ backgroundColor: theme.palette.primary.dark, color: 'white' }}
          />
          <Box
            component='form'
            onSubmit={handleSubmit(handleCreate)}
            noValidate
            sx={{ mt: 1 }}
          >
            <CardContent>
              <Grid
                container
                direction='column'
                alignItems='center'
                justifyContent='center'
                spacing={2}
              >
                <Grid item>
                  <TextField
                    {...register('name')}
                    margin='normal'
                    required
                    fullWidth
                    id='name'
                    label='Project Name'
                    name='name'
                    autoFocus
                    {...(errors.name && {
                      error: true,
                      helperText: errors.name.message,
                    })}
                    variant='filled'
                  />

                  <TextField
                    {...register('description')}
                    margin='normal'
                    required
                    fullWidth
                    id='description'
                    label='Project Description'
                    name='description'
                    {...(errors.description && {
                      error: true,
                      helperText: errors.description.message,
                    })}
                    variant='filled'
                  />

                  <TextField
                    hidden
                    {...register('creator')}
                    sx={{ display: 'none' }}
                    id='creator'
                    name='creator'
                    value={userId}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button onClick={() => reset()}>Reset</Button>
              <LoadingButton
                type='submit'
                isLoading={isLoading}
                variant='contained'
              >
                Create Project
              </LoadingButton>
            </CardActions>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};

export default ProjectCreateModal;
