import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import LoadingButton from '../../../../shared/components/ui/LoadingButton';

const ProjectDeleteModal = ({
  isOpen,
  setIsOpen,
  project,
  handleSnackbarUpdate,
  handleProjectDelete,
  type,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line
  const [isAssignment, setIsAssignment] = useState(type === 'assignments');

  const handleDelete = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (isAssignment) {
        const {data} = await axios.delete(
          `${process.env.REACT_APP_API_URL}/projects/${project.project}/assignments/${project._id}`
        );
        handleProjectDelete(project._id);
        handleSnackbarUpdate(data.message);
      }else{
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/projects/${project.id}`
      );
      handleProjectDelete(project.id);
      handleSnackbarUpdate(data.message);
      }
    } catch (error) {
      handleSnackbarUpdate(
        error.response?.data.message ||
          'Unable to update project. Please try again.'
      );
    }
    setIsLoading(false);
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        minWidth={theme.breakpoints.values.sm}
        justifyContent='center'
        alignItems='center'
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: `1px solid ${theme.palette.primary.dark}`,
          borderRadius: '8px',
          boxShadow: 12,
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card elevation={12} sx={{ width: '100%' }}>
          <CardHeader
            title={isAssignment ? 'Delete Assignment':'Delete Project'}
            action={
              <Tooltip title='Close'>
                <IconButton
                  aria-label='close'
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <CancelRoundedIcon />
                </IconButton>
              </Tooltip>
            }
            sx={{ backgroundColor: theme.palette.primary.dark, color: 'white' }}
          />
          <Box
            component='form'
            onSubmit={handleDelete}
            noValidate
            sx={{ mt: 1 }}
          >
            <CardContent>
              <Grid
                container
                direction='column'
                alignItems='center'
                justifyContent='center'
                spacing={2}
              >
                <Grid item>
                  <TextField
                    margin='normal'
                    fullWidth
                    id='name'
                    label={isAssignment ? 'Assigment Name' : 'Project Name'}
                    name='name'
                    variant='filled'
                    disabled
                    value={project.name}
                  />

                  <TextField
                    margin='normal'
                    fullWidth
                    id='description'
                    label={isAssignment ? 'Assigment Description':'Project Description'}
                    name='description'
                    variant='filled'
                    disabled
                    value={project.description}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <LoadingButton
                type='submit'
                isLoading={isLoading}
                variant='contained'
                color='error'
              >
                {isAssignment ? 'Delete Assignment':'Delete Project'}
              </LoadingButton>
            </CardActions>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};

export default ProjectDeleteModal;
