import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  SpeedDial,
  Button,
  SpeedDialAction,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../../shared/context/auth-context';
import { DataContext } from '../../shared/context/data-context';
import ProjectList from './components/project/ProjectList';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ProjectCreateModal from './components/project/ProjectCreateModal';
import IconTemplate from '../public/components/IconTemplate';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useLocation, useNavigate } from 'react-router-dom';
import AssignmentCreateModal from '../assigment/AssignmentCreateModal';

const LandingPage = ({ setSnackbar }) => {
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const dataContext = useContext(DataContext);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedProjects, setLoadedProjects] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isCreateAssignmentOpen, setIsCreateAssignmentOpen] = useState(false);
  const location = useLocation();
  const [isAssignment, setIsAssignment] = useState(location.pathname.includes('/assignments')); // Implement this in all the palce === 'assignments'
  const navigate = useNavigate();
  const [landingType, setLandingType] = useState('');
  const [project, setProject] = useState(null);
  const [projectName, setProjectName] = useState('');
 


  const handleSnackbarUpdate = useCallback((message) => {
    setSnackbar(true, message);
  }, [setSnackbar]);

  useEffect(() => {
    const newProject = dataContext.getProject();
    if (newProject?.id){
      setProject(newProject);
    }
    setIsAssignment(location.pathname.includes('/assignments'));
    setLandingType(isAssignment ? 'assignments' : 'projects');
    if(project?.name){
      setProjectName(project.name);
    }

     // clear the project from the context, it's set by the action links
    if (dataContext.getProject()?.name && !isAssignment) {
      dataContext.storeProject(); // clean up
    }

    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        //check if landingType is assignment
        if (!location.pathname.includes('/assignments')) {  //putting !isAssignment here breaks everything ¯\_(ツ)_/¯
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/projects/${auth.userId}`
          );
          setLoadedProjects(data.projects);
          handleSnackbarUpdate('Projects loaded.');
        } else if(project?.id) {
          //fetch assignments from project id
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/projects/${project?.id}/assignments`
          );
          setLoadedProjects(data.assignments);
          handleSnackbarUpdate('Assingments loaded.');
        }
      } catch (error) {
        setLoadedProjects([]);
        console.log(error);
        handleSnackbarUpdate(error.response?.data.message || 'Error processing request.');
        setError(error.response?.data.message || 'Error processing request.');
      }
      setIsLoading(false);
    };

    fetchProjects();
  }, [auth.userId, handleSnackbarUpdate, dataContext, landingType, project, location.pathname, location.state, isAssignment]);

  const handleProjectUpdate = useCallback(
    (id, newName, newDesc) => {
      const updatedProjects = loadedProjects.map((project) => {
        if (landingType === 'assignments') {
          if (project._id === id) {
            return { ...project, name: newName, description: newDesc };
          }
        } else if (project.id === id) {
          return { ...project, name: newName, description: newDesc };
        }
        return project;
      });
      setLoadedProjects(updatedProjects);
    },
    [loadedProjects, landingType]
  );

  const handleProjectCreate = useCallback(
    (project) => {
      setError(null);
      setLoadedProjects([...loadedProjects, project]);
    },
    [loadedProjects]
  );

  const handleProjectDelete = useCallback(
    (id) => {
      if (isAssignment) {
        const updatedProjects = loadedProjects.filter((project) => {
          return project._id !== id; // FKT - Filter Keeps True
        });
        setLoadedProjects(updatedProjects);
      } else {
        const updatedProjects = loadedProjects.filter((project) => {
          return project.id !== id; // FKT - Filter Keeps True
        });
        setLoadedProjects(updatedProjects);
      }
    },
    [loadedProjects, isAssignment]
  );

  return (
    <>
      <IconTemplate
        title={(isAssignment) ? `All ${projectName} Assignments` : 'Project Dashboard'}
        image={DashboardIcon} />
      {landingType === 'assignments' && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/landing')}
          sx={{ ml: 7, mb: 2 }}
        >
          Back to Dashboard
        </Button>
      )}
      {isLoading && (
        <Backdrop
          sx={{
            color: theme.palette.secondary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color='secondary' />
        </Backdrop>
      )}

      {!isLoading && loadedProjects && (
        <ProjectList
          userId={auth.userId || ''}
          projects={loadedProjects}
          type={isAssignment ? 'assignments' : 'projects'}
          error={error}
          setIsCreateModalOpen={isAssignment ? setIsCreateAssignmentOpen : setIsCreateModalOpen}
          handleSnackbarUpdate={handleSnackbarUpdate}
          handleProjectUpdate={handleProjectUpdate}
          handleProjectDelete={handleProjectDelete}
        />
      )}

      <ProjectCreateModal
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
        userId={auth.userId}
        handleSnackbarUpdate={handleSnackbarUpdate}
        handleProjectCreate={handleProjectCreate}
      />
      {project?._id && ( 
      <AssignmentCreateModal
        isOpen={isCreateAssignmentOpen}
        setIsOpen={setIsCreateAssignmentOpen}
        project={project}
        handleAssignmentCreate={handleProjectCreate}
        handleSnackbarUpdate={handleSnackbarUpdate}
      />
      )};

      <SpeedDial
        ariaLabel='SpeedDial create Project'
        sx={{ position: 'absolute', bottom: 72, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {(isAssignment) ? (
          <SpeedDialAction
            icon={<NoteAddIcon />}
            tooltipTitle={'Create Assignment'}
            onClick={() => {
              setIsCreateAssignmentOpen(true);
            }}
          />
        ) : (<SpeedDialAction
          icon={<NoteAddIcon />}
          tooltipTitle={'Create Project'}
          onClick={() => {
            setIsCreateModalOpen(true);
          }}
        />)}
      </SpeedDial>
    </>
  );
};

export default LandingPage;
