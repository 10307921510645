import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import OutlineDeleteModal from './OutlineDeleteModal';
import OutlineUpdateModal from './OutlineUpdateModal';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import styled from '@emotion/styled';
import { overflowString } from '../../../utils/helpers';

const OutlinesItem = ({
  outline,
  handleSnackbarUpdate,
  handleOutlineDelete,
  handleOutlineUpdate,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[400],
    },
  });

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card elevation={4}>
        <CardHeader
          title={
            <Tooltip title={outline.name}>
              <Typography fontWeight={700}>
                {overflowString(outline.name)}
              </Typography>
            </Tooltip>
          }
          subheader={
            <Typography variant='body2'>
              {new Date(outline.created).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </Typography>
          }
          action={
            <Tooltip title='Edit Outline Name/ Description'>
              <IconButton
                aria-label='edit'
                onClick={() => setIsUpdateModalOpen(true)}
              >
                <EditIcon color='primary' />
              </IconButton>
            </Tooltip>
          }
          sx={{ backgroundColor: theme.palette.grey[200] }}
        />
        <CardContent
          sx={{
            height: 120,
            background: theme.palette.grey[100],
          }}
        >
          <Typography textAlign='center' variant='body2' color='text.secondary'>
            Instruction count: {outline?.outline?.length || 0}
          </Typography>
          <br />
          <Typography textAlign='center' variant='body2' color='text.secondary'>
            Updated:{' '}
            {new Date(outline.lastUpdated).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <Tooltip title='Outline Helper'>
            <IconButton
              aria-label='helper'
              onClick={() => navigate(`/outline-builder/${outline.id}`)}
            >
              <AutoAwesomeIcon color='primary' fontSize='small' />
            </IconButton>
          </Tooltip>

          <Tooltip title='Edit outline'>
            <IconButton
              aria-label='edit outline'
              onClick={() => {
                navigate(`/outline/${outline.id}`);
              }}
            >
              <StyledBadge
                badgeContent={outline.outline.length}
                color='secondary'
              >
                <EditNoteIcon color='primary' fontSize='small' />
              </StyledBadge>
            </IconButton>
          </Tooltip>

          <Tooltip title='Delete Outline'>
            <IconButton
              aria-label='delete'
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <DeleteIcon
                sx={{ color: theme.palette.error.main }}
                fontSize='small'
              />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>

      <OutlineDeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        outline={outline}
        handleSnackbarUpdate={handleSnackbarUpdate}
        handleOutlineDelete={handleOutlineDelete}
      />

      <OutlineUpdateModal
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        outline={outline}
        handleSnackbarUpdate={handleSnackbarUpdate}
        handleOutlineUpdate={handleOutlineUpdate}
      />
    </Grid>
  );
};

export default OutlinesItem;
