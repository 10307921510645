import axios from 'axios';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
	Modal,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	Backdrop,
	CircularProgress,
	Container,
	useMediaQuery,
	useTheme
} from '@mui/material';

const ImageGalleryModal = ({
	isOpen,
	onClose,
	onSelectImage,
	projectId,
	setSnackbar,
	cachedImages,
	setCachedImages
}) => {
	const ITEMS_PER_PAGE = 25;								// number of images to load per page
	const [allImageFiles, setAllImageFiles] = useState([]); // all image file objects returned from the API
	const [images, setImages] = useState([]);               // images to display in this session
	const [currentPage, setCurrentPage] = useState(1);		// current set of images to display
	const [loading, setLoading] = useState(false);			// loading state for fetching thumbnails

	const theme = useTheme();

	// responsive columns and row height
	const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isSmScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
	const isMdScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));

	const getCols = () => {
		if (isXsScreen) return 1;
		if (isSmScreen) return 2;
		if (isMdScreen) return 3;
		return 4;
	};

	const getRowHeight = () => {
		if (isXsScreen) return 200;
		if (isSmScreen) return 220;
		if (isMdScreen) return 230;
		return 250;
	};

	const hasMore = images.length < allImageFiles.length;	// check if more images to load
	const containerRef = useRef(null);						// reference for the scrollable container

	// fetch all files once from the API when the modal opens
	const fetchAllFiles = useCallback(async () => {
		try {
			const { data: filesResponse } = await axios.get(`${process.env.REACT_APP_API_URL}/files/${projectId}/`);
			
			// keep only image files
			const imageFiles = filesResponse.files.filter(file => file.isImage);
			setAllImageFiles(imageFiles);
		} catch (error) {
			setSnackbar(true, error.response?.data.message || 'Unable to retrieve images');
		}
	}, [projectId, setSnackbar]);

	// load thumbnails for the files in the current page
	const loadThumbnailsForPage = useCallback(async (pageNum) => {
		setLoading(true);
		const startIndex = (pageNum - 1) * ITEMS_PER_PAGE;
		const endIndex = pageNum * ITEMS_PER_PAGE;
		
		// only process files whose thumbnails aren’t already in local state.
		const filesToLoad = allImageFiles
			.slice(startIndex, endIndex)
			.filter((file) => !images.some((img) => img.id === file._id));

		try {
			const newImagesPromises = filesToLoad.map(async file => {
				// check if thumbnail already cached
				const cached = (cachedImages || []).find(img => img.id === file._id);
				if (cached) {
					return cached;
				}
				
				// fetch the thumbnail if not cached
				const response = await axios.get(`${process.env.REACT_APP_API_URL}/cloud/thumbnail`,
					{
						params: {
							width: process.env.REACT_APP_THUMBNAIL_WIDTH,
							folder: file.bucket,
							filePath: file.inputPath
						},
						responseType: 'blob'
					}
				);

				// create blob URL for thumbnail image
				const blob = new Blob([response.data], { type: file.mimeType });
				const imageUrl = URL.createObjectURL(blob);
				return { id: file._id, src: imageUrl, name: file.name };
			});

			const newImagesResult = await Promise.all(newImagesPromises);
			
			// remove any null entries (if any error occurred for a file)
			const newImages = newImagesResult.filter(img => img !== null);

			// append to cached images with any new thumbnails that were fetched
			const thumbnailsToCache = newImages.filter(img => !(cachedImages || []).some(cached => cached.id === img.id));
			
			if (thumbnailsToCache.length > 0) {
				setCachedImages(prev => prev ? [...prev, ...thumbnailsToCache] : thumbnailsToCache);
			}

			// append the new images to the local images state for display
			setImages(prev => [...prev, ...newImages]);
		} catch (error) {
			setSnackbar(true, error.response?.data.message || 'Unable to retrieve thumbnails');
		} finally {
			setLoading(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allImageFiles, cachedImages, setSnackbar, setCachedImages]);

	// fetch files and initialize state when modal opens
	useEffect(() => {
		if (isOpen) {
			setAllImageFiles([]);

			// initialize local images if cached images are available
			if (cachedImages && cachedImages.length > 0) {
				setImages(cachedImages);
				
				// set the next page to load based # cached images
				const nextPage = Math.max(Math.floor(cachedImages.length / ITEMS_PER_PAGE), 1);
				setCurrentPage(nextPage);
			} else {
				setImages([]);
				setCurrentPage(1);
			}
			fetchAllFiles();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, fetchAllFiles, cachedImages]);

	// load the thumbnails as the current page changes
	useEffect(() => {
		if (allImageFiles.length > 0) {
			loadThumbnailsForPage(currentPage);
		}
	}, [allImageFiles, currentPage, loadThumbnailsForPage]);

	// scroll handler to load more images when near the bottom of the container
	const handleScroll = useCallback(() => {
		if (!containerRef.current) return;
		const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
		if (scrollHeight - scrollTop <= clientHeight + 350 && hasMore) {
			setCurrentPage(prev => prev + 1);
		}
	}, [hasMore]);

	// attach scroll event listener to the container element
	useEffect(() => {
		const container = containerRef.current;
		if (container) {
			container.addEventListener('scroll', handleScroll);
		}
		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}
		};
	}, [handleScroll]);

	return (
		<Modal open={isOpen} onClose={onClose}>
			<Container
				ref={containerRef}
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					bgcolor: 'background.paper',
					boxShadow: 24,
					padding: { xs: 1, sm: 2, md: 3 },
					width: { xs: '95%', sm: '90%', md: '85%', lg: '80%' },
					maxHeight: { xs: '90%', sm: '85%', md: '80%' },
					overflowY: 'auto',
				}}
			>
				<ImageList
					cols={getCols()}
					gap={9}
					rowHeight={getRowHeight()}
					sx = {{
						padding: '5px'
					}}
				>
					{images.map((image) => (
						<ImageListItem
							key={image.id}
							onClick={() => onSelectImage(allImageFiles.find(file => file._id === image.id))}
							sx={{
								cursor: 'pointer',
								display: 'flex',
								justifyContent: 'center',
								overflow: 'hidden',
								border: `1.5px solid ${theme.palette.divider}`,
								// borderRadius: theme.shape.borderRadius,
								transition: theme.transitions.create(['transform', 'box-shadow']),
								'&:hover': {
									transform: 'scale(1.02)',
									boxShadow: theme.shadows[4],
								}
							}}
						>
							<LazyLoadImage
								src={image.src}
								alt={image.name || `Image ${image.id}`}
								effect='blur'
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover'
								}}
							/>
							<ImageListItemBar
								title={image.name || `Image ${image.id}`}
								position="bottom"
								sx={{
									'& .MuiImageListItemBar-title': {
										fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }
									}
								}}
							/>
						</ImageListItem>
					))}
				</ImageList>
				<Backdrop
					sx={{
						color: '#adf',
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={loading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Container>
		</Modal>
	);
};

export default ImageGalleryModal;
