import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';

const CopyToClipboard = ({ text }) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Optional: Show a message or handle success
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        // Optional: Handle errors
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <Tooltip title={text}>
      <IconButton onClick={handleCopy} aria-label='copy' size='small'>
        <FileCopyIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export default CopyToClipboard;
