import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

const fontStyles = {
	topic: {
		header: { fontSize: '18px', fontWeight: 600 },
		caption: { fontSize: '16px', fontWeight: 400 },
	},
	subtopic: {
		header: { fontSize: '16px', fontWeight: 500 },
		caption: { fontSize: '14px', fontWeight: 400 },
	},
	body: {
		header: { fontSize: '14px', fontWeight: 400 },
		caption: { fontSize: '14px', fontWeight: 400 },
	},
	definition: {
		header: { fontSize: '14px', fontWeight: 400, fontStyle: 'italic' },
		caption: { fontSize: '14px', fontWeight: 400, fontStyle: 'italic' },
	},
	image: {
		header: { fontSize: '14px', fontWeight: 400 },
		caption: { fontSize: '14px', fontWeight: 400 },
	},
};

const characterLimits = {
	topic: {
		title: 75,
		prompt: 225,
	},
	subtopic: {
		title: 85,
		prompt: 225,
	},
	body: {
		title: 95,
		prompt: 225,
	},
	definition: {
		title: 95,
		prompt: 225,
	},
	image: {
		title: 95,
		prompt: 225,
	}
}

const ListItemCenter = ({ seq, title, prompt, section, handleItemUpdate }) => {
	const [itemTitle, setItemTitle] = useState(title);				// Track title content state
	const [isEditingTitle, setIsEditingTitle] = useState(false);	// Track editing state for title

	const [itemPrompt, setItemPrompt] = useState(prompt);			// Track prompt content state
	const [isEditingPrompt, setIsEditingPrompt] = useState(false);	// Track editing state for prompt

	//* helper functions for title editing
	const handleTitleChange = (event) => {
		if (event.target.value.length <= characterLimits[section].title) {
			setItemTitle(event.target.value);		// update the state as user modifies content
			title = event.target.value;				// directly reflect change in state without waiting for re-render
		}
	};

	const handleTitleClick = () => {
		setIsEditingTitle(true);
	};

	const handleTitleBlur = () => {
		if (itemTitle.length === 0) {
			setItemTitle(`Click to Edit ${section.charAt(0).toUpperCase() + section.slice(1)} Title`);
		}

		if (title !== itemTitle) {
			handleItemUpdate(seq, { title: itemTitle });
		}

		setIsEditingTitle(false);
	};

	//* helper functions for prompt editing
	const handlePromptChange = (event) => {
		if (event.target.value.length <= characterLimits[section].prompt) {
			setItemPrompt(event.target.value);
			prompt = event.target.value;
		}
	}

	const handlePromptClick = () => {
		setIsEditingPrompt(true);
	};

	const handlePromptBlur = () => {
		if (itemPrompt.length === 0) {
			setItemPrompt(`Click to Edit ${section.charAt(0).toUpperCase() + section.slice(1)} Prompt`);
		}

		if (prompt !== itemPrompt) {
			handleItemUpdate(seq, { prompt: itemPrompt });
		}

		setIsEditingPrompt(false);
	};

	const styles = fontStyles[section] || fontStyles.body;

	return (
		<Box sx={{ flex: 1, marginLeft: '15px' }}>
			{/* Title */}
			{isEditingTitle ? (
				<input
					value={itemTitle}
					onClick={(e) => e.stopPropagation()}
					onChange={handleTitleChange}
					onBlur={handleTitleBlur}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							handleTitleBlur();
						}
					}}
					autoFocus
					style={{
						...styles.header,
						minWidth: '85%',
						width: 'fit-content',
						maxWidth: '85%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						fontWeight: 'inherit',
						backgroundColor: 'transparent',
						padding: 0
					}}
				/>
			) : (
				<Typography
					sx={styles.header}
					onClick={(e) => {
						if (section !== 'image') {
							e.stopPropagation();
							handleTitleClick();
						}
					}}
					style={{
						...styles.header,
						width: 'fit-content',
						// maxWidth: '85%',
						// overflow: 'hidden',
						// textOverflow: 'ellipsis',
						// whiteSpace: 'nowrap',
						paddingRight: '5px',
						cursor: section !== 'image' ? 'text' : 'default',
					}}
				>
					{itemTitle}
				</Typography>
			)
			}

			{/* Prompt */}
			{section !== 'image' && (
				isEditingPrompt ? (
					<textarea
						rows={2}
						value={itemPrompt}
						onClick={(e) => e.stopPropagation()}
						onChange={(e) => {
							handlePromptChange(e);
						}}
						onBlur={handlePromptBlur}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handlePromptBlur();
							}
						}}
						autoFocus
						style={{
							...styles.caption,
							minWidth: '97%',
							width: 'fit-content',
							maxWidth: '97%',
							minHeight: '20px',
							height: 'auto',
							resize: 'none',
							outline: 'none',
							backgroundColor: 'transparent',
							padding: '8px',
							fontFamily: 'inherit',
						}}
					/>
				) : (
					<Typography
						onClick={(e) => {
							e.stopPropagation();
							handlePromptClick();
						}}
						style={{
							...styles.caption,
							width: 'fit-content',
							whiteSpace: 'pre-wrap',
							wordBreak: 'break-word'
						}}
					>
						{itemPrompt}
					</Typography>
				)
			)}

		</Box>
	);
};

export default ListItemCenter;
