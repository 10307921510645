import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { DataContext } from '../../shared/context/data-context';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UserSearch from './components/UserSearch';
import TabPanel from '../../shared/components/ui/TabPanel';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupsIcon from '@mui/icons-material/Groups';
import UserList from './components/UserList';
import SendIcon from '@mui/icons-material/Send';
// import { set } from 'react-hook-form';

const Collaborate = ({setSnackbar}) => {
  const dataContext = useContext(DataContext);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [userList, setUserList] = useState([]); // search results
  const [collaborators, setCollaborators] = useState([]); // exisitng collaborators
  const [hasSearchOccurred, setHasSearchOccurred] = useState(false);

  const fetchCollaborators = async (projectId) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/projects/collab/${projectId}`
      );
      setCollaborators(data.collaborators);
      setSnackbar(true, 'Collaboration details retrieved!');
    } catch (error) {
      console.error('Error fetching data:', error);
      setSnackbar(true, 'Unable to retrieve collaboration details.');
    }
    setIsLoading(false);
  };

  const updateCollaborators = async (projectId, userId, action) => {
    if (action !== 'add' && action !== 'remove') {
      setSnackbar(true, 'Invalid action attempted.');
      return;
    }

    const formData = { action, userId };

    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/projects/collab/${projectId}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const updatedUserList = userList.map((user) =>
        user.id === data.collabUser.id ? data.collabUser : user
      );

      setUserList(updatedUserList);

      fetchCollaborators(project.id);

      setSnackbar(true, 'Collaboration details updated!');
    } catch (error) {
      console.error('Error fetching data:', error);
      
      setSnackbar(true, 'Unable to update collaboration details.');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const project = dataContext.getProject();
    setProject(project);
    if (project.id) {
      // get initial data
      fetchCollaborators(project.id);
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext]);

  const handleUsers = useCallback((userList) => {
    // console.log(userList);
    setUserList(userList || []);
    setHasSearchOccurred(true);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddRemoveActions = (userId, actionType) => {
    // console.log(userId, actionType);
    updateCollaborators(project.id, userId, actionType);
  };

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: theme.palette.secondary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color='secondary' />
        </Backdrop>
      )}

      <Box
        sx={{ width: '100%', py: 2 }}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize='small' />}
          aria-label='breadcrumb'
        >
          <Link
            to={'/landing'}
            key='1'
            style={{
              textDecoration: 'none',
            }}
          >
            <Typography color='primary' fontSize={18} fontWeight={700}>
              <DashboardIcon fontSize='small' /> Dashboard
            </Typography>
          </Link>

          <Typography
            key='2'
            color='text.primary'
            fontSize={18}
            fontWeight={700}
          >
            {project?.name}
          </Typography>
          <Typography
            key='2'
            color='text.primary'
            fontSize={18}
            fontWeight={700}
          >
            Collaborate
          </Typography>
        </Breadcrumbs>
      </Box>
      {/* <Box
        sx={{ width: '100%', py: 2 }}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Typography fontSize={24}>Manage Access for {project.name}</Typography>
      </Box> */}

      <Container maxWidth='lg' sx={{ mt: 1, mb: 1 }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label='manage project access tabs'
            >
              <Tab
                label='Existing Collaborators'
                icon={
                  isLoading ? <CircularProgress size={20} /> : <GroupsIcon />
                }
                iconPosition='start'
              />
              <Tab
                label='Find Collaborators'
                icon={<GroupAddIcon fontSize='small' />}
                iconPosition='start'
              />
            </Tabs>
          </Box>

          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12}>
                <UserList
                  users={collaborators}
                  project={project}
                  handleAddRemoveActions={handleAddRemoveActions}
                  showEmptyMessage
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12}>
                <UserSearch resCallback={handleUsers} />
              </Grid>
              <Grid item xs={12}>
                <UserList
                  users={userList}
                  project={project}
                  handleAddRemoveActions={handleAddRemoveActions}
                />
              </Grid>

              {userList?.length === 5 && (
                <Grid item xs={12}>
                  <Box display='flex' justifyContent='flex-end'>
                    <Typography
                      variant='caption'
                      color={theme.palette.warning.main}
                    >
                      Too many records to display, you may need to refine your
                      search further.
                    </Typography>
                  </Box>
                </Grid>
              )}

              {hasSearchOccurred && (
                <Grid item xs={12}>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Typography variant='smallerFont'>
                      Don't see the person you want to collaborate with? Would
                      you like to invite them to join CorpusKey?
                    </Typography>

                    <Button
                      size='small'
                      variant='outlined'
                      color='primary'
                      startIcon={<SendIcon fontSize='small' />}
                      sx={{ ml: 1 }}
                      LinkComponent={Link}
                      to='/invite'
                    >
                      Invite
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </TabPanel>
        </Box>
      </Container>
    </>
  );
};

export default Collaborate;
