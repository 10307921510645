import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../../shared/context/auth-context';
import { DataContext } from '../../../shared/context/data-context';
import { useParams } from 'react-router-dom';
import ProjectStepper from '../../../shared/components/ui/ProjectStepper';
import {
  Backdrop,
  CircularProgress,
  Container,
  Typography,
  useTheme,
} from '@mui/material';
// import TopicForm from './TopicForm';
// import CourseForm from './CourseForm';
import TabForm from './TabForm';

const OutlineHelper = ({ setSnackbar }) => {
  const auth = useContext(AuthContext);
  const dataContext = useContext(DataContext);
  const { outlineId } = useParams('outlineId'); // outlineId from URL
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [jsonArray, setJsonArray] = useState([]);
  const [project, setProject] = useState();
  const [outlineName, setOutlineName] = useState();

  // eslint-disable-next-line
  const [topics, setTopics] = useState();

  useEffect(() => {
    const project = dataContext.getProject();
    setProject(project);

    const fetchOutline = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/outlines/outline/${outlineId}`
        );
        setJsonArray(data.outline.outline);
        setOutlineName(data.outline.name);
      } catch (error) {
        setJsonArray([]);
        setSnackbar(true, error.response?.data.message || 'Unable to retrieve outline.');
      }
      setIsLoading(false);
    };

    if (outlineId) {
      fetchOutline();
    }
  }, [outlineId, dataContext, setSnackbar]);


  // eslint-disable-next-line
  const chat = async (topic) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/ai/`,
        {
          messages: [
            { role: 'system', content: 'You are a college professor' },
            {
              role: 'user',
              content: `act as a college professor and recommend at least 12 chapters to be included in a book about ${topic} covering basic, intermediate and advanced topics. In addition add a one line description that explains why the chapter is included and how it helps teach the subject. The description should be written like a prompt that can be used to elaborate the chapter. The output should be an json array of objects each with the following keys: id, level, chapter, description`,
            },
          ],
          model: 'gpt-3.5-turbo',
          temperature: 0.7,
          penalty: 0,
          maxTokens: 1024,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      // console.log(data);
      setTopics(data.response);
    } catch (error) {
      setJsonArray([]);
      setSnackbar(true, error.response?.data.message || 'Unable to complete the request.');
    }
    setIsLoading(false);
  };


  const showSnackbarMessage = (messageStr) => {
    setSnackbar(true, messageStr)
  };

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: theme.palette.secondary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color='secondary' />
        </Backdrop>
      )}

      <ProjectStepper
        project={project}
        activeStep={1}
        crumb={`Outline: ${outlineName}`}
      />

      <Container sx={{ mt: 3 }} maxWidth='md'>
        <Typography textAlign={'center'} fontWeight={700} sx={{ my: 1 }}>
          OutlineHelper
        </Typography>

        <Typography variant='body2' textAlign='center'>
          Receive recommendations on how to organize your material into topics,
          subtopics etc.
        </Typography>
        {/* <TopicForm chat={chat} /> */}
        {/* <CourseForm chat={chat} showSnackbarMessage={showSnackbarMessage} /> */}
        <TabForm
          auth={auth}
          setIsLoading={setIsLoading}
          showSnackbarMessage={showSnackbarMessage}
        />
      </Container>
    </>
  );
};

export default OutlineHelper;
