import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../../shared/context/auth-context';
import SimpleCard from '../../shared/components/ui/SimpleCard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Tile from './components/Tile';
import Users from './components/Users';
import Files from './components/Files';
import Jobs from './components/Jobs';
import Alerts from './components/Alerts';
import IconTemplate from '../public/components/IconTemplate';
import Prompts from './components/Prompts';

const AdminPage = () => {
  const theme = useTheme();
  const auth = useContext(AuthContext);

  const [docCounts, setDocCounts] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [component, setComponent] = useState('');

  useEffect(() => {
    const fetchDocCountsData = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/doc-counts`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setDocCounts(data.stats);
      } catch (error) {
        setError(error.response?.data.message || 'Error processing request.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDocCountsData();
  }, [auth.token]);

  const handleCardClick = ({ key }) => {
    setComponent(key);
  };

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: theme.palette.secondary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color='secondary' />
        </Backdrop>
      )}

      <Container component='main' maxWidth='md' sx={{ mt: 2, mb: 2 }}>
        <IconTemplate title='Admin Dashboard' image={AdminPanelSettingsIcon} />

        {error ? (
          <Alert severity='warning'>{error}</Alert>
        ) : (
          <Grid container sx={{ mt: 1 }} justifyContent='space-between'>
            <Grid item xs={12} md={2}>
              <SimpleCard height={120} elevation={4}>
                <Tile
                  buttonText='View'
                  caption={docCounts?.users}
                  callback={handleCardClick}
                  params={{ key: 'users' }}
                >
                  Users
                </Tile>
              </SimpleCard>
            </Grid>
            <Grid item xs={12} md={2}>
              <SimpleCard height={120} elevation={4}>
                <Tile
                  buttonText='View'
                  caption={docCounts?.files}
                  callback={handleCardClick}
                  params={{ key: 'files' }}
                >
                  Files
                </Tile>
              </SimpleCard>
            </Grid>
            <Grid item xs={12} md={2}>
              <SimpleCard height={120} elevation={4}>
                <Tile
                  buttonText='View'
                  caption={docCounts?.jobs}
                  callback={handleCardClick}
                  params={{ key: 'jobs' }}
                >
                  Jobs
                </Tile>
              </SimpleCard>
            </Grid>
            <Grid item xs={12} md={2}>
              <SimpleCard height={120} elevation={4}>
                <Tile
                  buttonText='View'
                  caption={docCounts?.alerts}
                  callback={handleCardClick}
                  params={{ key: 'alerts' }}
                >
                  Alerts
                </Tile>
              </SimpleCard>
            </Grid>
            <Grid item xs={12} md={2}>
              <SimpleCard height={120} elevation={4}>
                <Tile
                  buttonText='View'
                  caption={docCounts?.prompts}
                  callback={handleCardClick}
                  params={{ key: 'prompts' }}
                >
                  Prompts
                </Tile>
              </SimpleCard>
            </Grid>
          </Grid>
        )}
      </Container>

      <Container maxWidth='md' sx={{ mt: 6, mb: 2 }}>
        {component === 'users' && <Users authToken={auth.token} />}

        {component === 'files' && <Files authToken={auth.token} />}

        {component === 'jobs' && <Jobs authToken={auth.token} />}

        {component === 'alerts' && <Alerts authToken={auth.token} />}

        {component === 'prompts' && (
          <Prompts authToken={auth.token} setIsLoading={setIsLoading} />
        )}
      </Container>
    </>
  );
};

export default AdminPage;
