import React, { Suspense, useState, useCallback, useMemo, useEffect } from 'react';

import {
	Navigate,
	Route,
	BrowserRouter as Router,
	Routes,
} from 'react-router-dom';


import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { CircularProgress, ThemeProvider, Snackbar, IconButton } from '@mui/material';

import MainNav from './shared/components/navigation/MainNav';
import { useAuth } from './shared/hooks/use-auth';
import { AuthContext } from './shared/context/auth-context';
import { useContextData } from './shared/hooks/use-context-data';
import { DataContext } from './shared/context/data-context';
import theme2 from './shared/theme/theme2';
import ScrollToTop from './utils/ScrollToTop';

import Home from './pages/home/Home';
import Login from './pages/user/Login';
import SignUp from './pages/user/Signup';
import Footer from './shared/components/navigation/Footer';
import Manage from './pages/user/Manage';
import Products from './pages/public/Products';
import About from './pages/public/About';
import Contact from './pages/public/Contact';
// import Help from './pages/public/Help';
import Faq from './pages/public/Faq';
import Terms from './pages/public/Terms';
import Privacy from './pages/public/Privacy';
import Page404 from './pages/public/Page404';
import LandingPage from './pages/project/LandingPage';
import FileManagementPage from './pages/project/FileManagementPage';
import OutlinePage from './pages/outline/OutlinePage';
import OutlinesPage from './pages/outline/OutlinesPage';
import GeneratePage from './pages/generate/GeneratePage';
import CriteriaPage from './pages/assigment/criteria';
import ContentLandingPage from './pages/content/ContentLandingPage';
import Collaborate from './pages/collaborate';
import Invite from './pages/invite';
import AdminPage from './pages/admin';
import OutlineBuilder from './pages/outline/OutlineBuilder';
// import PaymentHome from './pages/payment';
import UserSettings from './pages/user/UserSettings';
import Cookies from 'js-cookie';


// Lazy loaded
// const Help = React.lazy(() => import('./pages/public/Help'));
// const LandingPage = React.lazy(() => import('./pages/project/LandingPage'));
// const FileManagementPage = React.lazy(() =>
//   import('./pages/project/FileManagementPage')
// );
// const OutlinePage = React.lazy(() => import('./pages/outline/OutlinePage'));
// const OutlinesPage = React.lazy(() => import('./pages/outline/OutlinesPage'));
// const GeneratePage = React.lazy(() => import('./pages/generate/GeneratePage'));
// const ContentLandingPage = React.lazy(() =>
//   import('./pages/content/ContentLandingPage')
// );
const PaymentHome = React.lazy(() => import('./pages/payment'));

const App = () => {
	const { userId, token, user, login, logout } = useAuth();
	const { getProject, storeProject, refreshProject } = useContextData();

	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState();

	const contextData = useMemo(() => ({
		getProject,
		storeProject,
		refreshProject,
	}), [getProject, storeProject, refreshProject]);

	//this can be used to set future feature flags
	useEffect(() => {
		// Set the featureFlags cookie on startup
		Cookies.set('featureFlags', JSON.stringify({ assignmentFlag: false }), { expires: 7 });
	}, []);

	const memoizedSetSnackbar = useCallback((open, message) => {
		setSnackbarOpen(open);
		if (message) setSnackbarMessage(message);
	}, []);

	const memoizedAuthValue = useMemo(() => ({
		isLoggedIn: !!token,
		userId,
		token,
		user,
		login,
		logout,
	}), [userId, token, user, login, logout]);

	let routes = (
		<>
			<Route path='/' element={<Home />} />
			<Route path='/login' element={<Login />} />
			<Route path='/signup' element={<SignUp />} />
			<Route path='/manage/:action' element={<Manage />} />
			<Route path='/products' element={<Products />} />
			<Route path='/about' element={<About />} />
			<Route path='/contact' element={<Contact />} />
			<Route path='/help' element={<Faq />} />
			<Route path='/privacy' element={<Privacy />} />
			<Route path='/terms' element={<Terms />} />
			<Route path='/payments' element={<PaymentHome setSnackbar={memoizedSetSnackbar} />} />
			<Route path='/404' element={<Page404 />} />

			<Route path='*' element={<Navigate to='/' replace />} />

			{token && (
				<>
					<Route path='/landing' element={<LandingPage setSnackbar={memoizedSetSnackbar} />} />
					<Route path='/assignments' element={<LandingPage setSnackbar={memoizedSetSnackbar} />} />
					<Route path='/criteria' element={<CriteriaPage setSnackbar={memoizedSetSnackbar} />} />
					<Route path='files/:projectId/assignments/:assignmentId' element={<FileManagementPage setSnackbar={memoizedSetSnackbar} />} />
					<Route path='/files/:projectId' element={<FileManagementPage setSnackbar={memoizedSetSnackbar} />} />
					{/* <Route path='/outline/:outlineId' element={<OutlinePageMRT setSnackbar={memoizedSetSnackbar} />} /> */}
					{/* <Route path='/outline/:outlineId' element={<OutlineList setSnackbar={memoizedSetSnackbar} />} /> */}
					<Route path='/outline/:outlineId' element={<OutlinePage setSnackbar={memoizedSetSnackbar} />} />
					<Route path='/outlines/:projectId' element={<OutlinesPage setSnackbar={memoizedSetSnackbar} />} />
					<Route path='/generate/:projectId' element={<GeneratePage setSnackbar={memoizedSetSnackbar} />} />
					<Route path='/content/:projectId' element={<ContentLandingPage setSnackbar={memoizedSetSnackbar} />} />
					<Route path='/collab/:projectId' element={<Collaborate setSnackbar={memoizedSetSnackbar} />} />
					<Route path='/settings' element={<UserSettings />} />
					<Route path='/invite' element={<Invite />} />
					<Route path='/admin' element={<AdminPage />} />
					<Route path='/outline-builder/:outlineId' element={<OutlineBuilder setSnackbar={memoizedSetSnackbar} />} />
				</>
			)}
		</>
	);
	return (
		<>
			<AuthContext.Provider value={memoizedAuthValue}>
				<DataContext.Provider value={contextData}>
					<ThemeProvider theme={theme2}>
						<Router>
							<ScrollToTop />
							<MainNav />
							<div style={{ minHeight: '85vh' }}>
								<main>
									<Suspense fallback={<CircularProgress color='secondary' />}>
										<Routes>{routes}</Routes>
									</Suspense>
								</main>
							</div>
							<Footer />
						</Router>
					</ThemeProvider>
				</DataContext.Provider>
			</AuthContext.Provider>


			<Snackbar
				open={snackbarOpen}
				autoHideDuration={4000}
				onClose={() => setSnackbarOpen(false)}
				message={snackbarMessage}
				action={
					<IconButton
						size='small'
						aria-label='close'
						color='inherit'
						onClick={() => setSnackbarOpen(false)}
					>
						<CancelRoundedIcon fontSize='small' />
					</IconButton>
				}
			/>
		</>
	);
};

export default App;
